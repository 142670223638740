import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";

// formik-imports
import { useFormik } from "formik";
import * as Yup from "yup";

// ui-components
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import OnSuccessResponseIconView from "../../../../UI/OnSuccessResponseIconView/OnSuccessResponseIconView";

// api-config-imports
import axios from "axios";
import { APIConfig } from "../../../../services/apiConfiguration";

// utils-imports
import { randomUUID } from "../../../../services/randomUUID";
import paAPIEndpoints from "../../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// context-imports
import OnBoardCompanyPAContext from "../../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";
import OnBoardCompanyProdContext from "../../../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";

// styles-import
import "../CheckBoxFormStyles.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const validationSchema = Yup.object().shape({
  common: Yup.boolean(),
  payments: Yup.boolean(),
  core_banking: Yup.boolean(),
});

const ModuleSelectionForm = ({ setEnableModuleBtn }) => {
  // ^ context
  const { companyID } = useContext(OnBoardCompanyProdContext);

  // ^ button state on success
  const [onSuccess, setOnSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      common: true,
      payments: true,
      core_banking: true,
    },
    validationSchema,
    onSubmit: (values, action) => {
      // Filter the object and create an array of objects for true values
      const filteredSelectedModules = Object.keys(values)
        .filter((key) => values[key] === true)
        .map((key) => ({
          code: key,
          secret: randomUUID(),
        }));

      action.setSubmitting(true);

      APIConfig.API_Client.post(
        paAPIEndpoints.COMPANY_MODULE_SECRET.baseUrl +
          paAPIEndpoints.COMPANY_MODULE_SECRET.endpoint,
        { company_id: companyID, module_details: filteredSelectedModules },
        { cancelToken: source.token }
      )
        .then((response) => {
          ReactDOM.render(
            <SnackbarMessage msgtype="success" msg={response.data.message} />,
            document.getElementById("snackbar")
          );

          setOnSuccess(true);

          // To enable next jump to settlement account setup Tab validation state
          setEnableModuleBtn(true);

          // action.resetForm();
        })
        .catch((error) => {
          setOnSuccess(false);

          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error.response.data.message}
            />,
            document.getElementById("snackbar")
          );
        })
        .finally(() => {
          action.setSubmitting(false);
        });
    },
  });

  return (
    <div className="checkbox-form-wrapper">
      <form onSubmit={formik.handleSubmit}>
        <div className="formik-checkbox-field">
          <label className="formik-checkbox-field__label">
            <input
              type="checkbox"
              name="common"
              checked={formik.values.common}
              onChange={formik.handleChange}
              className="formik-checkbox-field__input"
              disabled
            />
            Common
          </label>
        </div>
        <div className="formik-checkbox-field">
          <label className="formik-checkbox-field__label">
            <input
              type="checkbox"
              name="payments"
              checked={formik.values.payments}
              onChange={formik.handleChange}
              className="formik-checkbox-field__input"
              disabled
            />
            Payments
          </label>
        </div>
        <div className="formik-checkbox-field">
          <label className="formik-checkbox-field__label">
            <input
              type="checkbox"
              name="core_banking"
              checked={formik.values.core_banking}
              onChange={formik.handleChange}
              className="formik-checkbox-field__input"
              disabled
            />
            Core Banking
          </label>
        </div>

        {onSuccess ? (
          <OnSuccessResponseIconView />
        ) : (
          <button
            className={`formik-btn-submit ${
              (formik.values.common ||
                formik.values.payments ||
                formik.values.core_banking) &&
              formik.dirty &&
              !formik.isSubmitting
                ? "active"
                : ""
            } ${
              formik.isSubmitting ||
              !(
                formik.values.common ||
                formik.values.payments ||
                formik.values.core_banking
              )
                ? "disabled"
                : ""
            }`}
            type="submit"
            disabled={
              formik.isSubmitting ||
              !(
                formik.values.common ||
                formik.values.payments ||
                formik.values.core_banking
              )
            }
          >
            {formik.isSubmitting ? "Loading..." : "Submit"}
          </button>
        )}
      </form>
    </div>
  );
};

export default ModuleSelectionForm;
