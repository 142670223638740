import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { APIConfig } from "../../services/apiConfiguration";
import { randomUUID } from "../../services/randomUUID";
import SnackbarMessage from "../../components/SnackbarMessage/SnackbarMessage";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const OnBoardCompanyProdContext = createContext();

export const OnBoardCompanyProdContextProvider = ({ children }) => {
  const [tabTypePA, setTabTypePA] = useState("companyDetailsPA");
  //& Types of tab
  // companyDetailsPA
  // moduleProviderPA
  // companyChannelPA
  // settlementAccountPA
  // dmo
  // paymentChannelPA
  // credentialsPA

  //& Common Response state's
  const [companyID, setCompanyID] = useState(null);
  const [consumerURN, setConsumerURN] = useState(null);
  const [companyCommonName, setCompanyCommonName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  //& Merchant Category Code state
  const [merchantDetails, setMerchantDetails] = useState([]);
  //& Consumer Type state
  const [consumerTypeDetails, setConsumerTypeDetails] = useState([]);
  //& State Code & ID state
  const [stateDetails, setStateDetails] = useState([]);

  //& KYB detail-- for multi data
  const [data, setData] = useState(null);
  //& KYB detail-- for single data
  const [kybData, setKybData] = useState(null);
  //& KYB loading state
  const [isKybLoading, setIsKybLoading] = useState(false);

  //& Tab Parent state
  // * on-boarding state
  const [onboardingStep, setOnboardingStep] = React.useState(tabTypePA);
  //& Types of tab
  // companyDetailsPA
  // moduleProviderPA
  // companyChannelPA
  // settlementAccountPA
  // credentialsPA
  // dmo

  const [tabDisabled, setTabDisabled] = React.useState({
    companyDetailsPA: false,
    moduleProviderTabPA: true,
    companyChannelPA: true,
    settlementAccountPA: true,
    credentialsPA: true,
    dmo: true,
    paymentChannelPA: true,
  });

  //& Settlement Account PA
  const [settlementLayoutTab, setSettlementLayoutTab] =
    useState("multi-attempt");
  // multi-attempt
  // settlement-config

  //& CompanyDetailsPA state's
  // TODO : KYB WILL HAVE THIS IN RESPONSE
  // DO KEEP A CHANGE HERE FOR THIS
  const [companyDetailOwnershipType, setCompanyDetailOwnershipType] =
    useState("");

  //& Function Handlers

  //& Tab to Module Tab
  const toModuleProvider = () => {
    updateTab("moduleProviderTabPA");
  };

  //& Tab to Company Channel Tab
  const toCompanyChannel = () => {
    updateTab("companyChannelPA");
  };

  //& Tab to SettlementAccount Tab handler
  const toSettlementAccount = () => {
    updateTab("settlementAccountPA");
  };

  //& Tab to Summary Tab handler
  const toSummary = () => {
    updateTab("credentialsPA");
  };

  //& Tab to DMO Tab handler
  const toDMO = () => {
    updateTab("dmo");
  };

  //& Tab to Payment Channel Tab handler
  const toPaymentChannel = () => {
    updateTab("paymentChannelPA");
  };

  //& Tab to CompanyDetail Tab handler
  const toCompanyDetails = () => {
    updateTab("companyDetailsPA");

    // Reset initial
    setKybData(null);
    setData(null);
  };

  //& Tab to Settlement Tab handler
  const toSettlementConfig = () => {
    setSettlementLayoutTab("settlement-config");
  };

  //& Tab Update function handler
  const updateTab = (tabName) => {
    let tempTabsData = tabDisabled;
    for (const [key] of Object.entries(tempTabsData)) {
      if (key === tabName) {
        tempTabsData[key] = false;
      } else {
        tempTabsData[key] = true;
      }
    }
    setTabDisabled(tempTabsData);
    setOnboardingStep(tabName);
  };

  //& Company Secrets generation API
  const companySecretCreationAPI = (companySecretInputData) => {
    APIConfig.API_Client.post(
      paAPIEndpoints.COMPANY_SECRET_DATA.baseUrl +
        paAPIEndpoints.COMPANY_SECRET_DATA.endpoint,
      companySecretInputData
    )
      .then((response) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        //& Move to Module Tab
        toModuleProvider();
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  //& fetchMerchantCategory
  const fetchMerchantCategory = () => {
    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_MERCHANT_DETAILS.baseUrl +
        paAPIEndpoints.FETCH_MERCHANT_DETAILS.endpoint,
      {},
      { cancelToken: source.token }
    )
      .then((response) => {
        setMerchantDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //& fetchConsumerType
  const fetchConsumerType = () => {
    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_CONSUMER_TYPE.baseUrl +
        paAPIEndpoints.FETCH_CONSUMER_TYPE.endpoint,
      {},
      { cancelToken: source.token }
    )
      .then((response) => {
        const options = response.data.map((consumerType) => ({
          value: consumerType.code,
          label: consumerType.name,
        }));
        setConsumerTypeDetails(options);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //& fetchStateDetails
  const fetchStateDetails = () => {
    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_STATE_DETAILS.baseUrl +
        paAPIEndpoints.FETCH_STATE_DETAILS.endpoint,
      {},
      { cancelToken: source.token }
    )
      .then((response) => {
        const options = response.data.map((country) => ({
          // value: country.country_id,
          value: randomUUID(),
          label: country.state,
        }));

        setStateDetails(options);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchMerchantCategory();
    fetchStateDetails();
    fetchConsumerType();
  }, []);

  //& Master Account Setup API
  // provider_code passed in header

  const masterAccountSetupAPI = () => {
    let masterSetupPayload = {
      company_id: companyID,
      provider_code: "yesb",
    };

    APIConfig.API_Client.defaults.headers.post["provider_code"] = "yesb";

    APIConfig.API_Client.post(
      paAPIEndpoints.COMPANY_MASTER_SETUP.baseUrl +
        paAPIEndpoints.COMPANY_MASTER_SETUP.endpoint,
      masterSetupPayload
    )
      .then((response) => {
        // saving urn to state
        setConsumerURN(response.data.data.master_consumer_urn || false);

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  //& fetchStateDetails
  const fetchKYBDetails = (phoneNumber) => {
    setIsKybLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_KYB_DETAILS_ONBOARDING.baseUrl +
        paAPIEndpoints.FETCH_KYB_DETAILS_ONBOARDING.endpoint,
      { phone_number: phoneNumber },
      { cancelToken: source.token }
    )
      .then((response) => {
        setIsKybLoading(false);

        // Set empty full data
        // setKybData(null);
        setData(null); // Multi-data

        // Handle kybData based on response type
        if (Array.isArray(response.data)) {
          if (response.data.length > 1) {
            setData(response.data); // Multi-data
          } else if (response.data.length === 1) {
            setKybData(response.data[0]); // Single data
          } else {
            setKybData(null);
            setIsKybLoading(false);
            console.warn("No KYB data available:", response.data);

            // Snackbar

            ReactDOM.render(
              <SnackbarMessage
                msgtype="Error"
                msg={"No records found for this phone number"}
              />,
              document.getElementById("snackbar")
            );
          }
        } else {
          setIsKybLoading(false);
          setKybData(null);
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        setIsKybLoading(false);
        console.log(error);
      });
  };

  return (
    <OnBoardCompanyProdContext.Provider
      value={{
        // common state's
        companyID,
        setCompanyID,
        companyCommonName,
        setCompanyCommonName,
        phoneNumber,
        setPhoneNumber,
        // merchant detail state
        merchantDetails,
        // consumer details state
        consumerTypeDetails,
        // state detail state
        stateDetails,
        // Tab state's
        tabTypePA,
        onboardingStep,
        tabDisabled,
        settlementLayoutTab,
        // companyDetailsPA
        setCompanyDetailOwnershipType,
        companyDetailOwnershipType,
        companySecretCreationAPI,
        // moduleProviderPA
        masterAccountSetupAPI,
        consumerURN,
        // tab switch handlers
        toCompanyDetails,
        toModuleProvider,
        toCompanyChannel,
        toSettlementAccount,
        toSettlementConfig,
        toSummary,
        toDMO,
        toPaymentChannel,
        // FIX
        data,
        fetchKYBDetails,
        kybData,
        setKybData,
        isKybLoading,
      }}
    >
      {children}
    </OnBoardCompanyProdContext.Provider>
  );
};

export default OnBoardCompanyProdContext;
