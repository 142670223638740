import React from "react";
import MultiAttemptProd from "../../MultiAttemptProd/MultiAttemptProd";
import SettlementAccountProd from "../../SettlementAccountProd/SettlementAccountProd";
import TabComponentCardProdWrapper from "../../../../UI/TabComponentCardProdWrapper/TabComponentCardProdWrapper";

const SettlementAccountLayout = () => {
  return (
    <React.Fragment>
      <TabComponentCardProdWrapper
        heading=""
        tabs={[
          {
            className: "",
            label: "Multi Attempt",
            value: "multi-attempt",
            component: <MultiAttemptProd />,
          },
          {
            className: "",
            label: "Settlement Config",
            value: "settlement-config",
            component: <SettlementAccountProd />,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default SettlementAccountLayout;
