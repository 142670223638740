import React, { useState, useContext } from "react";

// ui-components
import { Accordion } from "../../../UI/Accordion/Accordion";
import ProviderSelectionForm from "./ProviderSelectionForm/ProviderSelectionForm";
import ModuleSelectionForm from "./ModuleSelectionForm/ModuleSelectionForm";
// import MasterLedgerAccount from "./MasterLedgerAccount/MasterLedgerAccount";

// context-imports
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";
import OnBoardCompanyProdContext from "../../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";

// styles import
import "./ModuleProviderProd.scss";

const ModuleProviderProd = () => {
  // ^ context
  const { toCompanyChannel } = useContext(OnBoardCompanyProdContext);

  // & btn state handlers
  const [enableModuleBtn, setEnableModuleBtn] = useState(false);
  const [enableProviderBtn, setEnableProviderBtn] = useState(false);

  return (
    <div className="module-provider-prod-form">
      {/* <FormHeading headingText="Fill module and provider details to continue" /> */}
      <div className="module-selection">
        {/* Modules  */}
        <Accordion heading={"Modules"} defaultOpen={true} closable={false}>
          <ModuleSelectionForm setEnableModuleBtn={setEnableModuleBtn} />
        </Accordion>

        {/* Provider  */}
        <Accordion heading={"Providers"} defaultOpen={true} closable={false}>
          <ProviderSelectionForm setEnableProviderBtn={setEnableProviderBtn} />
        </Accordion>

        {/* Master Account Setup */}
        {/* <Accordion heading={" Master Account Setup "}>
          <MasterLedgerAccount />
        </Accordion> */}

        {/* Next Tab button */}
      </div>
      <div className="module-provider-btn-container">
        <button
          className={`formik-btn-submit ${
            enableModuleBtn && enableProviderBtn ? "active" : ""
          } ${enableModuleBtn && enableProviderBtn ? "" : "disabled"}`}
          type="submit"
          disabled={!(enableModuleBtn && enableProviderBtn)}
          onClick={toCompanyChannel}
        >
          Add Company Channel
        </button>
      </div>
    </div>
  );
};

export default ModuleProviderProd;
