import React, { useContext } from "react";

// component-imports
import Box from "@material-ui/core/Box";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";

// context-import
import OnBoardCompanyProdContext from "../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";

//styles-import
import "./TabComponentCardProdWrapper.scss";

const TabComponentCardProdWrapper = ({ heading, tabs }) => {
  const { settlementLayoutTab } = useContext(OnBoardCompanyProdContext);

  return (
    <React.Fragment>
      <div className="tab-card-components-page">
        <div className="tab-card-components-content">
          <div className="tab-card-components">
            <h1 className="heading">{heading}</h1>
          </div>
          <div className="tab-card-components-forms">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={settlementLayoutTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList>
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        className={`${tab.className} tab-card-module`}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </TabList>
                </Box>
                {tabs.map((tab) => (
                  <TabPanel key={tab.value} value={tab.value}>
                    <div id={tab.value}>{tab.component}</div>
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TabComponentCardProdWrapper;
