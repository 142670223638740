import React, { useEffect, useState, useCallback } from "react";
//API-imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

// components import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import SettlementViewDetailBlock from "./SettlementViewDetailBlock";

// styles import
import "./SettlementView.scss";

// Move to constants file
const INITIAL_STATE = {
  settlementUrnOptions: [],
  settlementViewDetails: [],
  settlementList: [],
  isLoading: true,
  error: null,
};

function getDateFromTransaction({ days_before_today, hour, minutes, seconds }) {
  const date = new Date();
  date.setDate(date.getDate() - days_before_today);
  date.setHours(hour, minutes, seconds);

  return date;
}

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SettlementView = () => {
  const [state, setState] = useState(INITIAL_STATE);
  const [settlementCycleUrn, setSettlementCycleUrn] = useState(null);
  const [openAccordions, setOpenAccordions] = useState({});

  const fetchSettlementCycles = async () => {
    try {
      const response = await APIConfig.API_Client.post(
        `${apiEndpointList.FETCH_SETTLEMENT_CYCLE.baseUrl}${apiEndpointList.FETCH_SETTLEMENT_CYCLE.endpoint}`,
        {},
        { cancelToken: source.token }
      );

      const uniqueOptions = Array.from(
        new Set(response.data.map((item) => item.name))
      ).map((name) => ({
        value: response.data.find((item) => item.name === name).urn,
        label: name,
      }));

      setState((prev) => ({
        ...prev,
        settlementViewDetails: response.data,
        settlementList: response.data,
        settlementUrnOptions: uniqueOptions,
        isLoading: false,
      }));
    } catch (error) {
      if (!axios.isCancel(error)) {
        setState((prev) => ({
          ...prev,
          error: "Failed to fetch settlement cycles",
          isLoading: false,
        }));
      }
    }
  };

  useEffect(() => {
    fetchSettlementCycles();
    // return () => source.cancel("Component unmounted");
  }, []);

  useEffect(() => {
    if (settlementCycleUrn) {
      const filterDetails = (arr, filterOption) => {
        return arr.filter((item) => item.name === filterOption);
      };

      setState((prev) => ({
        ...prev,
        settlementList: filterDetails(
          state.settlementViewDetails,
          settlementCycleUrn.label
        ),
      }));
    }
  }, [settlementCycleUrn]);

  // Improve accordion toggle
  const toggleAccordion = useCallback((index) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }, []);

  if (state.isLoading)
    return <div className="settlement-view__loading">Loading...</div>;
  if (state.error)
    return <div className="settlement-view__error">{state.error}</div>;

  return (
    <div className="settlement-view">
      <div className="ui-form-details">
        <div className="ui-form-content">
          <div className="ui-form-inputs-section">
            {/* urn options */}
            <TextFieldSelect
              id="settlement_cycle_urn"
              name="settlement_cycle_urn"
              onChange={(selectedOption) =>
                setSettlementCycleUrn(selectedOption)
              }
              options={state.settlementUrnOptions}
              noOptionsMessage={() => "No such cycle exists"}
              label="Settlement Cycle"
              required={true}
              isLoading={state.isLoading}
              placeholder="Select settlement cycle"
              isformatOptionLabel={false}
              isClearable={false}
            />
          </div>
        </div>

        {/* Updated Details List */}
        {state.settlementList.map((details, index) => (
          <div className="response-details-container" key={index}>
            <div
              className="accordion-header"
              onClick={() => toggleAccordion(index)}
              style={{ cursor: "pointer" }}
            >
              <h4 className="response-details-heading">
                <p>
                  {details?.name || "-"}
                  <span className="tooltip-container">
                    <span
                      className="info-icon"
                      onClick={(e) => e.stopPropagation()}
                    >
                      ⓘ
                    </span>
                    <span className="tooltip-text">
                      {details?.description || "No description available"}
                    </span>
                  </span>
                </p>
                <span className="accordion-icon">
                  {openAccordions[index] ? "▼" : "▶"}
                </span>
              </h4>
            </div>

            {openAccordions[index] && (
              <>
                <div className="response-details-divider"></div>
                <div className="response-details">
                  <ul className="response-list">
                    <li className="response-list-item">
                      <span className="field">Settlement Cycle URN: </span>
                      <span className="value">{details?.urn || "-"}</span>
                    </li>
                    <li className="response-list-item">
                      <span className="field">Provider Code: </span>
                      <span className="value">
                        {details?.provider_code || "-"}
                      </span>
                    </li>
                    <li className="response-list-item">
                      <span className="field">
                        Underlying Account Consumer Account ID:{" "}
                      </span>
                      <span className="value">
                        {details?.underlying_account_consumer_bank_account_id ||
                          "-"}
                      </span>
                    </li>
                    <li className="response-list-item">
                      <span className="field">Description: </span>
                      <span className="value">
                        {details?.description || "-"}
                      </span>
                    </li>
                    <SettlementViewDetailBlock settlementUrn={details?.urn || ""} />
                    {/* <li className="response-list-item">
                      <span className="field">Consumers: </span>
                      <span className="consumers-value">
                        {details?.consumers?.map((consumer) => (
                          <span key={consumer}>{consumer}</span>
                        )) || "-"}
                      </span>
                    </li> */}
                    <li className="response-list-item">
                      <span className="field">Channels: </span>
                      <span className="value">
                        {details?.channels?.join(",  ") || "-"}
                      </span>
                    </li>
                    <li className="response-list-item">
                      <span className="field">Transactions Between: </span>
                      <span className="value">
                        {details?.transactions_between?.from
                          ? new Date(
                              getDateFromTransaction(
                                details.transactions_between.from
                              )
                            ).toLocaleString()
                          : "-"}
                        {" - "}
                        {details?.transactions_between?.till
                          ? new Date(
                              getDateFromTransaction(
                                details.transactions_between.till
                              )
                            ).toLocaleString()
                          : "-"}
                      </span>
                    </li>
                    <li className="response-list-item">
                      <span className="field">Channel Config: </span>
                      <span className="value">
                        {details?.channel_config ? (
                          <>
                            {Object.keys(details.channel_config).map(
                              (channel) => (
                                <div key={channel}>
                                  <strong
                                    style={{ textDecoration: "underline" }}
                                  >
                                    {channel}
                                  </strong>
                                  <ul>
                                    {details.channel_config[channel]
                                      .invoke_settlement_activity !==
                                      undefined && (
                                      <li>
                                        <span className="value">
                                          Invoke Settlement Activity:{" "}
                                        </span>
                                        <span className="value">
                                          {details.channel_config[
                                            channel
                                          ].invoke_settlement_activity.toString()}
                                        </span>
                                      </li>
                                    )}
                                    {details.channel_config[channel]
                                      .invoke_record_settlement !==
                                      undefined && (
                                      <li>
                                        <span className="value">
                                          Invoke Record Settlement:{" "}
                                        </span>
                                        <span className="value">
                                          {details.channel_config[
                                            channel
                                          ].invoke_record_settlement.toString()}
                                        </span>
                                      </li>
                                    )}
                                    {details.channel_config[channel]
                                      .settlement_record_action && (
                                      <li>
                                        <span className="value">
                                          Settlement Record Action:{" "}
                                        </span>
                                        <span className="value">
                                          {
                                            details.channel_config[channel]
                                              .settlement_record_action
                                          }
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(SettlementView);
