import React, { useContext } from "react";

// context imports
import OnBoardCompanyProdContext from "../../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";
import { KYBProvider } from "../../../contexts/CustomerManagementProdContext/KYBContext";

// ui components
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import TabHeading from "../../../UI/TabHeading/TabHeading";

// #
import CompanyDetailsProd from "../CompanyDetailsProd/CompanyDetailsProd";
import ModuleProviderProd from "../ModuleProviderProd/ModuleProviderProd";
import CompanyChannelProd from "../CompanyChannelProd/CompanyChannelProd";
import SettlementAccountLayout from "../CompanyDetailsProd/SettlementAccountLayout/SettlementAccountLayout";
import DMOVPALayout from "../DMOandVPAProd/DMOVPALayout";
import PaymentChannelProd from "../PaymentChannelProd/PaymentChannelProd";
import OnBoardCredentialsProd from "../OnBoardCredentialsProd/OnBoardCredentialsProd";

// styles import
import "./OnBoardCompanyProd.scss";

const OnBoardCompanyProd = () => {
  // ^ context
  const { onboardingStep, tabDisabled } = useContext(OnBoardCompanyProdContext);

  return (
    <div className="onboard-customer-prod-page">
      <div className="onboard-customer-prod-content">
        <div className="onboard-customer-prod">
          <TabHeading headingText="Onboard Company" />
        </div>

        {/* Tabs */}
        <div className="onboard-customer-forms">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={onboardingStep}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList variant="scrollable">
                  <Tab
                    className="companyDetails onboard-tab"
                    disabled={tabDisabled.companyDetailsPA}
                    label="Company Details"
                    value="companyDetailsPA"
                  />
                  <Tab
                    className="moduleProvider onboard-tab"
                    disabled={tabDisabled.moduleProviderTabPA}
                    label="Module & Provider"
                    value="moduleProviderTabPA"
                  />
                  <Tab
                    className="companyChannel onboard-tab"
                    disabled={tabDisabled.companyChannelPA}
                    label="Company Channel"
                    value="companyChannelPA"
                  />
                  <Tab
                    className="settlementAccount onboard-tab"
                    disabled={tabDisabled.settlementAccountPA}
                    label="Settlement Account"
                    value="settlementAccountPA"
                  />

                  <Tab
                    className="summary onboard-tab"
                    disabled={tabDisabled.dmo}
                    label="VPA & DMO"
                    value="dmo"
                  />
                  <Tab
                    className="paymentChannel onboard-tab"
                    disabled={tabDisabled.paymentChannelPA}
                    label="Payment Channel"
                    value="paymentChannelPA"
                  />
                  <Tab
                    className="summary onboard-tab"
                    disabled={tabDisabled.credentialsPA}
                    label="Credentials"
                    value="credentialsPA"
                  />
                </TabList>
              </Box>
              <TabPanel value="companyDetailsPA">
                <div id="companyDetailsPA">
                  <KYBProvider>
                    <CompanyDetailsProd />
                  </KYBProvider>
                </div>
              </TabPanel>
              <TabPanel value="moduleProviderTabPA">
                <div id="moduleProviderTabPA">
                  <ModuleProviderProd />
                </div>
              </TabPanel>
              <TabPanel value="companyChannelPA">
                <div id="companyChannelPA">
                  <CompanyChannelProd />
                </div>
              </TabPanel>
              <TabPanel value="settlementAccountPA">
                <div id="settlementAccountPA">
                  <SettlementAccountLayout />
                </div>
              </TabPanel>

              <TabPanel value="dmo">
                {/* <div id="dmo">
                  <VPA />
                </div> */}
                <DMOVPALayout />
              </TabPanel>
              <TabPanel value="paymentChannelPA">
                <div id="paymentChannelPA">
                  <PaymentChannelProd />
                </div>
              </TabPanel>
              <TabPanel value="credentialsPA">
                <div id="credentialsPA">
                  <OnBoardCredentialsProd />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
        {/* Tabs ends */}
      </div>
    </div>
  );
};

export default OnBoardCompanyProd;
