import React, { createContext, useState, useCallback, useContext } from "react";
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// Create the KYB context
const KYBContext = createContext();

// KYB Provider Component
export const KYBProvider = ({ children }) => {
  //& KYB detail-- for multi data
  const [data, setData] = useState(null);
  //& KYB detail-- for single data
  const [kybData, setKybData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch KYB Details (normal function, not wrapped in useCallback)
  const fetchKYBDetails = async (phoneNumber) => {
    const source = axios.CancelToken.source();

    setLoading(true);
    setError(null);

    try {
      const response = await APIConfig.API_Client.post(
        paAPIEndpoints.FETCH_KYB_DETAILS_ONBOARDING.baseUrl +
          paAPIEndpoints.FETCH_KYB_DETAILS_ONBOARDING.endpoint,
        { phone_number: phoneNumber },
        { cancelToken: source.token }
      );

      // Set empty full data
      setKybData(null);

      // Handle kybData based on response type
      if (Array.isArray(response.data)) {
        if (response.data.length > 1) {
          setData(response.data); // Multi-data
        } else if (response.data.length === 1) {
          setKybData(response.data[0]); // Single data
        } else {
          setKybData(null);
          console.warn("No KYB data available:", response.data);
        }
      } else {
        setKybData(null);
        console.error("Unexpected response format:", response.data);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("Request canceled:", err.message);
      } else if (err.response) {
        console.error("Server Error:", err.response.data, err.response.status);
      } else if (err.request) {
        console.error("No Response:", err.request);
      } else {
        console.error("Error:", err.message);
      }
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  // Expose context values
  return (
    <KYBContext.Provider
      value={{ data, kybData, setKybData, loading, error, fetchKYBDetails }}
    >
      {children}
    </KYBContext.Provider>
  );
};

// Hook to use KYB context
export const useKYB = () => useContext(KYBContext);

//* s2:
// import React from "react";
// import { KYBProvider } from "./path/to/KYBContext";
// import YourComponent from "./path/to/YourComponent";

// const App = () => (
//   <KYBProvider>
//     <YourComponent />
//   </KYBProvider>
// );

// export default App;

//* s3:
// You can now use the useKYB hook inside any component to access the fetchKYBDetails method, data, loading, and error.
// import React, { useEffect } from "react";
// import { useKYB } from "./path/to/KYBContext";

// const KYBConsumerComponent = () => {
//   const { data, loading, error, fetchKYBDetails } = useKYB();

//   useEffect(() => {
//     fetchKYBDetails("89789478900"); // Fetch details on component mount
//   }, [fetchKYBDetails]);

//   return (
//     <div>
//       <h1>KYB Details</h1>
//       {loading && <p>Loading...</p>}
//       {error && <p style={{ color: "red" }}>Error: {error.message}</p>}
//       {data && (
//         <div>
//           <h2>Details:</h2>
//           <pre>{JSON.stringify(data, null, 2)}</pre>
//         </div>
//       )}
//     </div>
//   );
// };

// export default KYBConsumerComponent;
