import React from "react";
import ReactDOM from "react-dom";

import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// style imports
import "./ConsumerListDialog.scss";

function ConsumerListDialog({ listData }) {
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("add-customer-components")
    );
  };

  return (
    <React.Fragment>
      <Dialog
        className="consumers-details"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-content-container">
            <div className="success-message">
              <h3>Company Consumers List</h3>
            </div>
            <div class="consumer-urn__details">
              {listData.map((item, index) => (
                <div className="consumers-urn__value" key={index}>
                  <strong className="consumer-value__label">
                    Company Name:
                  </strong>{" "}
                  <span className="consumer-value">{item.company_name}</span>{" "}
                  <br />
                  <strong className="consumer-value__label">
                    Consumers:
                  </strong>{" "}
                  <span className="consumer-value">
                    {item.consumers.join(", ")}
                  </span>
                </div>
              ))}
            </div>

            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ConsumerListDialog;
