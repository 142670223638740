import React from "react";
import "./VerifiedAccountBlock.scss";

const VerifiedAccountBlock = ({
  verificationDetails,
  selectedKeys,
}) => {
  const hasValidValue = (value) =>
    value !== undefined && value !== null && value !== "";

  return (
    <div className="verified-settlement-container">
      <div className="verified-success-account-details">
        <table>
          <tbody>
            {selectedKeys.map((key) => {
              const value = verificationDetails[key];
              if (hasValidValue(value)) {
                const formattedKey = key
                  .replace(/([A-Z])/g, " $1")
                  .toUpperCase();
                return (
                  <tr className="row-account__details" key={key}>
                    <td className="detail-heading">{formattedKey}</td>
                    <td className="detail-value title-case">{value}</td>
                  </tr>
                );
              }
              return null; // Skip rendering if value is not valid
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VerifiedAccountBlock;
