import ReactDOM from "react-dom";

/* eslint-disable react/react-in-jsx-scope */
import CloseIcon from "@material-ui/icons/Close";
import React, { useRef, useState } from "react";
import ReactDom from "react-dom";
import { APIConfig } from "../../../services/apiConfiguration";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
// styles
import "./TotpModal.scss";

export const TotpModal = ({
  setShowTotpModal,
  showTotp,
  setShowTotp,
  dataToVerify,
  submitAfterTotp,
  qrBase,
  formik,
}) => {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowTotpModal(false);
      formik.setSubmitting(false);
    }
  };

  // TOTP Component
  const TotpModal = () => {
    const [errorTotpMessage, seterrorTotpMessage] = useState(null);
    const [otpValue, setOtpValue] = useState("");
    const [otpInvalid, setOtpInvalid] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const otpInputHandler = (e) => {
      // Restrict input to numeric characters only
      const numericInput = e.target.value.replace(/\D/, "");
      setOtpValue(numericInput);
      setOtpInvalid(false);
      if (e.target.value.length > 5) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    };
    const submitHandler = (e) => {
      e.preventDefault();
      const payloadData = {
        ...dataToVerify,
        otp: otpValue,
      };
      APIConfig.API_Client.post(
        paAPIEndpoints.VERIFY_TOTP.baseUrl + "/v2/mfa/verify",
        // paAPIEndpoints.VERIFY_TOTP.endpoint,
        payloadData
      )
        .then((res) => {
          if (res.status === 200) {
            submitAfterTotp(formik);
            setShowTotpModal(false);
          }

          // if (res.status === 200 && res.data.response_key == "success_verify") {
          //   if (res.data.response_message == "Verify OTP Successful") {
          //     if (formik) {
          //       submitAfterTotp(formik);
          //     } else {
          //       submitAfterTotp();
          //     }
          //     setShowTotpModal(false);
          //   }
          // } else {
          //   setOtpInvalid(true);
          // }
        })
        .catch((error) => {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error.response.data.response_message}
            />,
            document.getElementById("snackbar")
          );
          seterrorTotpMessage(error.response.data.response_message);
          console.error(e);
          setOtpInvalid(true);
        });
    };

    return (
      <>
        <form onSubmit={submitHandler}>
          <div className="totp-heading">
            <p>Validate TOTP</p>
          </div>
          <p className="sub-heading-totp__alert">
            Your request has been submitted. To proceed, you'll need an OTP
            (One-Time Password). <br /> Please contact the designated approver
            to provide you with the OTP to proceed.
          </p>
          <div className="edit-credit-fields">
            <div className="text-input-wrapper">
              <div className="text-input-label">Google Auth TOTP</div>
              <div className="add-totp-input">
                <input
                  value={otpValue}
                  type="text"
                  placeholder="Enter OTP"
                  className="totp-field-input"
                  onChange={otpInputHandler}
                  maxLength={6}
                />
              </div>
            </div>
          </div>

          {otpInvalid && (
            <div className="error-response-block-permission">
              <p>{errorTotpMessage}</p>
            </div>
          )}
          <div className="totp-button-wrapper">
            <button
              disabled={isDisabled}
              className={`update-credit-btn ${
                !isDisabled && "active-update-btn"
              }`}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </>
    );
  };

  const QRModal = () => {
    return (
      <>
        <div className="totp-heading">
          <h3>Scan the code for the Google Auth Setup</h3>
        </div>
        <div className="qr-container">
          <img
            className="qr-OTP"
            src={`data:image/png;base64, ${qrBase}`}
            alt="qr sample"
          />
        </div>
        <div className="totp-button-wrapper">
          <button
            className="update-credit-btn active-update-btn"
            onClick={() => setShowTotp(true)}
            type="button"
          >
            Done
          </button>
        </div>
      </>
    );
  };

  return ReactDom.createPortal(
    <div
      className="all-callbacks-container"
      ref={modalRef}
      onClick={closeModal}
    >
      <div className="modal">
        <button
          className="close-modal-btn"
          onClick={() => {
            setShowTotpModal(false);
            formik.setSubmitting(false);
          }}
        >
          <CloseIcon />
        </button>
        {/* Only when showTOTP is true and qrbase is not a empty string(inital state) 'QR modal' is displayed. */}
        {!showTotp && qrBase.length > 0 && <QRModal />}
        {showTotp && <TotpModal />}
      </div>
    </div>,
    document.getElementById("portal")
  );
};
