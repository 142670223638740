import React, { useContext, useEffect } from "react";

//component-import
import FormHeading from "../../../UI/FormHeading/FormHeading";
import Divider from "../../../UI/Divider/Divider";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import CategoryOneType from "./CategoryOneType/CategoryOneType";
import CategoryTwoType from "./CategoryTwoType/CategoryTwoType";
import CategoryThreeType from "./CategoryThreeType/CategoryThreeType";
import CategoryFourType from "./CategoryFourType/CategoryFourType";
// import FormTitle from "../../../UI/FormTitle/FormTitle";

//context-import
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";
import OnBoardCompanyProdContext from "../../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";
import { REGEXP } from "../../../utilities/validators/inputValidators";
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import { useKYB } from "../../../contexts/CustomerManagementProdContext/KYBContext";
import MessageBlock from "../../../UI/MessageBlock/MessageBlock";

// Default value for Ownership type
const initialValues = {
  phone_number: "",
  email: "",
  company_name: "",
  // TODO : Remove
  company_kyb_id: {},
};

const { mobileRegex, fullNameRegex } = REGEXP;

const validationSchema = Yup.object({
  phone_number: Yup.string()
    // .matches(mobileRegex, "Invalid mobile number")
    // .max(10)
    .required("Mobile number is required"),
  email: Yup.string().email("Invalid email address"),
  company_name: Yup.string().matches(
    fullNameRegex,
    "Invalid characters in Company Name"
  ),
  // TODO : Remove
  // company_kyb_id: Yup.object().shape({
  //   value: Yup.string().required("Please select an option"),
  //   label: Yup.string().required("Please select an option"),
  // }),
});

const CompanyDetailsProd = () => {
  // ^ context
  const {
    setPhoneNumber,
    fetchKYBDetails,
    kybData,
    setKybData,
    data,
    isKybLoading,
  } = useContext(OnBoardCompanyProdContext);
  // // KYB
  // const { data, kybData, setKybData, loading, error, fetchKYBDetails } =
  //   useKYB();

  // ^ formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      // setKybData(null);

      fetchKYBDetails(values?.phone_number);

      //* Update:: phone_number state
      setPhoneNumber(values?.phone_number);

      // fetchKYBDetails(values?.phone_number);

      // if (loading) {
      //   formik.setSubmitting(true);
      // }

      formik.setSubmitting(false);
    },
  });

  const COMPANY_DETAIL_CARD = {
    "Sole Proprietorship": <CategoryOneType />,
    "Individuals/Professionals": <CategoryOneType />,
    //
    "Hindu Undivided Family (HUF)": <CategoryTwoType />,
    //
    Partnership: <CategoryFourType />,
    "Private Limited": <CategoryThreeType />,
    "Public Limited": <CategoryThreeType />,
    "Limited Liability Partnership (LLP)": <CategoryThreeType />,
    Society: <CategoryThreeType />,
    Trust: <CategoryThreeType />,
    Government: <CategoryThreeType />,
    "NGO (Section 8)": <CategoryThreeType />,
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="ui-form-details">
        <FormHeading headingText="Enter Company Details" />
        <div className="ui-form-content">
          {/* <FormTitle titleText="Ownership Type" /> */}
          {/* #1 */}
          <div className="ui-form-inputs-section">
            {/* Phone number */}
            <TextFieldInput
              id="phone_number"
              name="phone_number"
              // onChange={formik.handleChange}
              onChange={(e) => {
                formik.setFieldValue("phone_number", e.target.value.trim());
              }}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
              touched={formik.touched.phone_number}
              error={formik.errors.phone_number}
              placeholder="Enter phone number"
              label="Phone Number"
              // maxLength={10}
              isToolTip={
                "Please enter the primary phone number of the organization you wish to onboard to proceed."
              }
              required={true}
              disabled={false}
            />

            {/* Email */}
            <TextFieldInput
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              touched={formik.touched.email}
              error={formik.errors.email}
              placeholder="Enter email"
              label="Email"
              required={false}
              disabled={false}
            />
          </div>

          {/* 2 */}
          <div className="ui-form-inputs-section">
            {/* Company Name */}
            <TextFieldInput
              id="company_name"
              name="company_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.company_name}
              touched={formik.touched.company_name}
              error={formik.errors.company_name}
              placeholder="Enter company name"
              label="Company Name"
              required={false}
              disabled={false}
            />
          </div>

          {/* submit-button-starts */}
          <div className="ui-button-container">
            <button
              className={`${
                formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : ""
              }`}
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {isKybLoading ? "Loading..." : "Submit"}
            </button>
          </div>
          {/* submit-button-ends */}

          {/* Multi Select Option for multiple company on same phone number */}
          {data?.length > 1 && (
            <div className="ui-form-details">
              {/* Ownership Type */}
              <FormHeading headingText="Select Company" />
              <div className="ui-form-content">
                {/* <FormTitle titleText="Ownership Type" /> */}

                <div className="ui-form-inputs-section">
                  {/* Select DropDown */}
                  <TextFieldSelect
                    id="company_kyb_id"
                    name="company_kyb_id"
                    onChange={(selectedOption) => {
                      formik.setFieldValue("company_kyb_id", selectedOption);
                      setKybData(selectedOption); // Set the KYB detail
                    }}
                    onBlur={() =>
                      formik.setFieldTouched("company_kyb_id", true)
                    }
                    // value={formik.values.company_kyb_id}
                    options={data.map((item) => ({
                      ...item, // Include all original keys
                      label: item.common_name, // Add label
                      value: item._id, // Add value
                    }))}
                    isToolTip={
                      "Companies are listed in the dropdown in descending order of creation date."
                    }
                    noOptionsMessage={() => "No such company exists"}
                    label="Company"
                    required={true}
                    isClearable={false}
                    //   isLoading={isLoading}
                    placeholder="Select Company Name"
                  />
                </div>
              </div>
            </div>
          )}

          <Divider />

          {data?.length > 1 && !kybData?.entity_type ? (
            <MessageBlock
              message="Your search has yielded multiple companies. Please choose one to proceed."
              styleClass="onboarding-form-message-block"
            />
          ) : null}

          {/* Category Based Form */}
          {kybData?.entity_type ? (
            <div>{COMPANY_DETAIL_CARD[kybData?.entity_type]}</div>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default CompanyDetailsProd;
