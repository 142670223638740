import React from "react";
// component imports
import CopyResponseListObjects from "../../UI/CopyResponseListObjects/CopyResponseListObjects";
// utility functions
import { convertCasesToCapitalize } from "../../utilities/convertCasesToCapitalize";
//styles-import
import "./MasterSearchResponseDetails.scss";

const MasterSearchResponseDetails = ({ data, onClick }) => {
  // Defensive check to ensure data is an array
  // const responseData = Array.isArray(data) ? data : [];
  const responseData =
    typeof data === "object" && !Array.isArray(data) ? [data] : [];

  const formattedData = responseData.map((item) =>
    convertCasesToCapitalize(item)
  );

  console.log(formattedData);

  return (
    <div className="api-response-details-container">
      <h4 className="api-response-details-heading">
        Search Results
        <div className="right-icons-container">
          <CopyResponseListObjects
            label="Copy All"
            color="primary"
            variant="outlined"
            data={formattedData}
            iconSrc="/images/copy-icon.svg"
          />
          <img
            src="/images/close.svg"
            className="close-api-response-details"
            alt="close"
            onClick={onClick}
          />
        </div>
      </h4>
      <div className="api-response-details-divider"></div>
      <div className="api-response-details">
        <ul className="api-response-list">
          <li className="api-response-list-item">
            {Object.entries(formattedData[0]).map(([key, value]) => (
              <div key={key}>
                <span className="field">{`${key}: `}</span>
                <span className="value">{value || "-"}</span>
              </div>
            ))}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MasterSearchResponseDetails;
