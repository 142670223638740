import React, { useState } from "react";
import ReactDOM from "react-dom";

// api config imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
// component imports
import DataTable from "./DataTable";
import TableLoader from "../Shared/TableLoader/TableLoader";
import Error from "../Shared/Error/Error";
import VADetailsDialog from "../VirtualAccounts/VADetailsDialog/VADetailsDialog";
import FailureDialog from "../Shared/FailureDialog/FailureDialog";
// style imports
import "./VirtualAccountsDataTable.scss";


const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const VirtualAccountsDataTable = () => {
    const getVirtualAccountDetails = (VANumber) => {
        APIConfig.API_Client.post(
            apiEndpointList.GET_VIRTUAL_ACCOUNTS_DETAILS.baseUrl +
            apiEndpointList.GET_VIRTUAL_ACCOUNTS_DETAILS.endpoint,
            {
                "virtual_account_number": VANumber,
            },
            { cancelToken: source.token }
        )
            .then((response) => {
                ReactDOM.render(
                    <VADetailsDialog data={response.data} vaNumber={VANumber} />,
                    document.getElementById("add-customer-components")
                );
            })
            .catch((error) => {
                ReactDOM.render(
                    <FailureDialog />,
                    document.getElementById("failure-popup")
                );
            });

    }

    const handleClick = (VANumber) => {
        getVirtualAccountDetails(VANumber)
    }

    const VAHeader = React.useMemo(
        () => [
            {
                Header: "_",
                hideHeader: false,
                columns: [
                    {
                        Header: "Company Name",
                        accessor: "company_name",
                        className: "header-small",
                        sort: false,
                    },
                    {
                        Header: "Consumer URN",
                        accessor: "consumer_urn",
                        className: "header-small",
                        sort: false,
                    },
                    {
                        Header: "VA Number",
                        accessor: "virtual_account_number",
                        className: "header-small",
                        sort: false,
                        Cell: ({ row }) => (
                            <div
                                style={{ textAlign: "center", cursor: "pointer", "color": "#0092ff" }}
                                onClick={() => handleClick(row.original.virtual_account_number)
                                }
                            >
                                {row.original.virtual_account_number}

                            </div>
                        ),
                    },
                    {
                        Header: "Bank Ref Number",
                        accessor: "bank_reference_number",
                        className: "header-small",
                        sort: false,
                    },
                    {
                        Header: "Created on",
                        accessor: "transaction_timestamp",
                        className: "header-small",
                        sort: true,
                    },
                    {
                        Header: "Decentro Transaction ID",
                        accessor: "decentro_transaction_id",
                        className: "header-small",
                        sort: false,
                    },
                    {
                        Header: "Transfer Type",
                        accessor: "transfer_type",
                        className: "header-small",
                        sort: false,
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        className: "header-small",
                        sort: false,
                    },
                    {
                        Header: "Amount",
                        accessor: "amount",
                        className: "header-small",
                        sort: true,
                    },
                    {
                        Header: "Remarks",
                        accessor: "remarks",
                        className: "header-small",
                        sort: false,
                    },
                    {
                        Header: "Payer Account No",
                        accessor: "payer_account_number",
                        className: "header-small",
                        sort: false,
                    },
                    {
                        Header: "Payer Account IFSC",
                        accessor: "payer_account_ifsc",
                        className: "header-small",
                        sort: false,
                    },
                    {
                        Header: "Payer Name",
                        accessor: "payer_name",
                        className: "header-small",
                        sort: false,
                    }
                ]
            },
        ],
        []
    );

    // Utility function to format date for filtering
    const getDateFormatted = (date) => {
        const dateObj = new Date(date);
        const month = dateObj.getMonth() + 1;
        const dateTime = dateObj.getDate();
        return `${dateObj.getFullYear()}-${month > 9 ? month : `0${month}`}-${dateTime > 9 ? dateTime : `0${dateTime}`
            }`;
    };

    // states
    const [VAData, setVAData] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);

    // Error Handling states
    const [isError, setIsError] = React.useState(false);
    const [errorResponse, setErrorResponse] = React.useState({
        status: "",
        message: "",
    });
    // Date States
    let initialStartDate = new Date();
    initialStartDate.setDate(initialStartDate.getDate() - 14);
    const [dateFilters, setDateFilters] = useState([
        initialStartDate,
        new Date(),
    ]);

    // * Virtual Accounts Data
    const getVirtualAccounts = () => {
        setIsLoading(true)
        APIConfig.API_Client.post(
            apiEndpointList.GET_VIRTUAL_ACCOUNTS.baseUrl +
            apiEndpointList.GET_VIRTUAL_ACCOUNTS.endpoint,
            {
                "start_date": getDateFormatted(dateFilters[0]),
                "end_date": getDateFormatted(dateFilters[1]),
            },
            { cancelToken: source.token }
        )
            .then((response) => {
                setVAData(response.data);
                setIsLoading(false)
            })
            .catch((error) => {
                console.error("Error fetching virtual accounts:", error);
                setIsError(true);
                setErrorResponse({
                    status: error.response.status,
                    message: error.message,
                });
                setIsLoading(false)
            });
    };

    React.useEffect(() => {
        getVirtualAccounts();
    }, [dateFilters]);

    return (
        <div className="virtual-accounts-table-container">
            {!isError ? (
                !isLoading ? (
                    <DataTable
                        isDateFilter={true}
                        dateFilters={dateFilters}
                        setDateFilters={setDateFilters}
                        columns={VAHeader}
                        data={VAData}
                    />
                ) : (
                    <TableLoader />
                )
            ) : (
                <Error type={errorResponse} />
            )}
        </div>
    );
};

export default VirtualAccountsDataTable;
