import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API imports
import { APIConfig } from "../../../services/apiConfiguration";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

//component-import
import MultiInputField from "../../../UI/MultiInputField/MultiInputField";
import TextFieldInputCustom from "../../../UI/TextField/TextFieldInputCustom";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import { TotpModal } from "../TotpModal/TotpModal";
import ResponseDetails from "../ResponseDetails/ResponseDetails";

// context imports
import IPManagementActionContext from "../../../contexts/IPManagementActionContext";

// utils import
import { randomUUID } from "../../../services/randomUUID";
import { REGEXP } from "../../../utilities/validators/inputValidators";

// Define validation schema with Yup
const validationSchema = Yup.object({
  requestType: Yup.string().required("Request Type is required"),
  ipAddresses: Yup.array()
    .of(
      Yup.string()
        .required("IP Address is required")
        .matches(
          /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/,
          "Invalid IP address"
        )
        .test(
          "not-blacklisted",
          "This IP address is blacklisted",
          (value) => !["3.7.18.204", "0.0.0.0"].includes(value)
        )
        .test("is-india-ip", "IP address must be from India", async (value) => {
          if (!value) return true; // Skip if no value
          const response = await fetch(
            `https://in.qa.decentro.tech/validate_ip/${value}`
          );
          // const response = await fetch(`http://ip-api.com/json/${value}`);
          const data = await response.json();
          return data.country === "India";
        })
    )
    .max(3, "You can only enter up to 3 IP addresses")
    .required("At least one IP Address is required")
    .test(
      "at-least-one-ip-address",
      "At least one IP Address is required",
      (value) => value && value.length > 0
    ),
});

const ManageIpsForm = () => {
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);

  const { tableEditData } = useContext(IPManagementActionContext);

  const [showTotpModal, setShowTotpModal] = useState(false);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitIPs = (formik) => {
    const { values, action } = formik;
    const payload = {
      ip_address: values.ipAddresses,
      request_type: values.requestType,
      company_id: tableEditData.id,
    };
    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.REQUEST_IP_WHITELIST.baseUrl +
        paAPIEndpoints.REQUEST_IP_WHITELIST.endpoint,
      payload
    )
      .then((response) => {
        if (response?.data && response?.data?.error) {
          // API call successful but with an error response
          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={response?.data?.message || "Something went wrong!"}
            />,
            document.getElementById("snackbar")
          );
        } else {
          // Handle successful response
          if (response?.data?.status === "Failed") {
            ReactDOM.render(
              <SnackbarMessage msgtype="error" msg={response?.data?.message} />,
              document.getElementById("snackbar")
            );
          } else if (response?.data?.status === "Success") {
            setResponseDetails({
              ...responseDetails,
              responseData: response.data,
              renderData: payload,
            });
            setShowResponseDetails(true);
            formik.resetForm();
          }
        }
      })
      .catch((error) => {
        // Handle request error
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={
              error?.response?.message ||
              error?.response?.data?.message ||
              "Something went wrong!"
            }
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      requestType: tableEditData.pluginCreated ? "update" : "create",
      ipAddresses: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  const extraHint = {
    create: "This will create a new plugin If no IP address is allowed",
    update: "This will add to the allowed list of IP addresses",
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          {/* <div className="ui-divider"></div> */}
          <div className="ui-form-content ui-divider">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldInputCustom
                id="requestType"
                name="requestType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.requestType}
                label="Request Type"
                required={true}
                placeholder="Select Request Type"
                touched={formik.touched.requestType}
                error={formik.errors.requestType}
                disabled={true}
                displayValue={
                  formik.values.requestType === "update" ? "Add" : "Create"
                }
                extraHint={extraHint}
              />
              <MultiInputField
                id="ipAddresses"
                name="ipAddresses"
                value={formik.values.ipAddresses}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                touched={formik.touched.ipAddresses}
                error={formik.errors.ipAddresses}
                placeholder={
                  formik.values.ipAddresses.length > 0
                    ? ""
                    : "Enter IP Address(es)"
                }
                label="IP Address(es)"
                required={true}
                extraHint={
                  "Press Enter after entering each IP Address. Max 3 IP Addresses allowed"
                }
              />
            </div>

            <div className="ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          // submitCallback={editNewUser}
          submitCallback={(formik) => submitIPs(formik)}
          formik={formik}
        />
      ) : null}
      {showResponseDetails && (
        <ResponseDetails data={responseDetails} onClick={handleClick} />
      )}
    </>
  );
};

export default ManageIpsForm;
