import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { APIConfig, APIConfigFormData } from "../../services/apiConfiguration";

//component-import
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import DownloadButton from "../../UI/DownloadButton/DownloadButton";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import { BulkMasterSearchSuccessModal } from "./BulkMasterSearchSuccessModal";
import { TotpModal } from "./TotpModal/TotpModal";

//utility-import
import { REGEXP } from "../../utilities/validators/inputValidators";
import { randomUUID } from "../../services/randomUUID";
import { scrollIntoView } from "../../utilities/scrollIntoView";

//styles-import
import "../../UI/TextField/TextField.scss";

const initialValues = {
  file: null,
  purpose: "",
};

const { alphanumericWithWhitespaceRegex } = REGEXP;
const validationSchema = Yup.object({
  file: Yup.mixed()
    .required("File is required")
    .test(
      "fileType",
      "File format is wrong, please upload the correct file.",
      (value) => value && value.type === "text/csv"
    )
    .test(
      "fileSize",
      "Size of the file is more than 100 MB, please try again.",
      (value) => value && value.size <= 100 * 1024 * 1024 // 100 MB
    ),
  purpose: Yup.string()
    .min(5)
    .max(50, "Purpose message is more than 50 characters, please retry.")
    .matches(alphanumericWithWhitespaceRegex)
    .required("Purpose message is required"),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const BulkMasterSearch = () => {
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const responseRef = useRef(null);

  const bulkSearchSubmit = () => {
    const file = document.getElementById("file").files[0];
    const formData = new FormData();
    formData.set("input", file);
    formData.append("reference_id", randomUUID());
    formData.append("purpose", formik.values.purpose);
    formData.append("input_type", "csv");
    formik.setSubmitting(true);
    APIConfigFormData.API_Client.post(
      paAPIEndpoints.BULK_MASTER_SEARCH.baseUrl +
        paAPIEndpoints.BULK_MASTER_SEARCH.endpoint,
      formData,
      { cancelToken: source.token }
    )
      .then((response) => {
        setResponseDetails(response.data);
        setShowResponseDetails(true);
        const fileInput = document.getElementById("file");
        if (fileInput) {
          fileInput.value = "";
        }
        formik.resetForm();

        // Show success modal with response data
        setModalData({
          status: response.data.status,
          url: response.data.download_url,
          message: response.data.message,
        });
        setShowSuccessModal(true);
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error?.message || "Something went wrong!";
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={errorMessage} />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
        if (responseRef.current) {
          scrollIntoView(responseRef.current, { behavior: "smooth" });
        }
      });
  };

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 100 * 1024 * 1024) {
      // 100 MB
      formik.setFieldError(
        "file",
        "Size of the file is more than 100 MB, please try again."
      );
    } else {
      formik.setFieldValue("file", file);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-header">
            <h2 className="ui-form-title">
              Fill transaction details to continue
            </h2>
            <DownloadButton
              fileSrc="/files/fetch_transaction_bulk_sample.csv"
              downloadedFilename="Sample.csv"
              text="Sample File"
            />
          </div>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="purpose"
                name="purpose"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose}
                touched={formik.touched.purpose}
                error={formik.errors.purpose}
                placeholder="Enter Purpose Message"
                label="Purpose Message"
                required={true}
                disabled={false}
                isToolTip={true ? "Min 5 characters" : null}
                maxLength={50}
              />
            </div>
            <div className="ui-form-inputs-section">
              <div className={`ui-form-input-section ui-form-content-input`}>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                  required={true}
                />
                <label htmlFor="file" className="ui-file-label">
                  {"Upload CSV File"} <span className="required-field">*</span>
                </label>
                {formik.values.file &&
                formik.values.file.type !== "text/csv" ? (
                  <img
                    src="/images/invalid-input.svg"
                    className="ui-invalid-file-type"
                    alt="invalid-input"
                  />
                ) : null}
              </div>
            </div>
            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={bulkSearchSubmit}
          formik={formik}
        />
      ) : null}
      {showSuccessModal && (
        <BulkMasterSearchSuccessModal
          setShowModal={setShowSuccessModal}
          message={modalData.message}
          url={modalData.url}
        />
      )}
    </>
  );
};

export default BulkMasterSearch;
