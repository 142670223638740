import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";
import { randomUUID } from "../../services/randomUUID";

//API-imports
import axios from "axios";
import { REGEXP } from "../../utilities/validators/inputValidators";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

//component-import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import ToggleField from "../../UI/ToggleField/ToggleField";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import { TotpModal } from "./TotpModal/TotpModal";
import ResponseDetails from "../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../UI/TextField/TextField.scss";

const initialValues = {
  company_name: "",
  user_type: "",
  user_name: "",
  email: "",
  mobile: "",
  password: "",
  is_active: true,
};

// for conditional rendering. 
const communicationUser = "communication_user"
// Define a constant for the COMMUNICATION USER value
const COMMUNICATION_USER_VALUE = process.env.REACT_APP_ENV_NAME === "qa" ? "10" : "9";

// regex-imports
const { usernameRegex, phoneNumberRegex, passwordRegex } = REGEXP;

const validationSchema = Yup.object({
  company_name: Yup.object().shape({
    value: Yup.number().required("Please Select an Option"),
    label: Yup.string().required("Please Select an Option"),
  }),
  user_type: Yup.object().shape({
    value: Yup.string().required("Please Select an Option"),
    label: Yup.string().required("Please Select an Option"),
  }),
  user_name: Yup.string()
    .matches(usernameRegex, "Invalid Name format")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(phoneNumberRegex, "Invalid Mobile number")
    .max(10)
    .required("Mobile number is required"),
  password: Yup.string().when('user_type', {
    is: (user_type) => user_type && user_type?.name !== communicationUser,
    then: () => Yup.string()
      .matches(passwordRegex, "Invalid Password format")
      .required("Password is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  is_active: Yup.boolean().when('user_type', {
    is: (user_type) => user_type && user_type?.name !== communicationUser,
    then: () => Yup.boolean().required("Boolean Status required"),
    otherwise: () => Yup.boolean().notRequired(),
  }),
});

let userTypeDropDownValues = [
  {
    value: "3",
    label: "ADMIN",
  },
  {
    value: "4",
    label: "USER",
  },
  {
    value: COMMUNICATION_USER_VALUE,
    label: "COMMUNICATION USER",
  },
];

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const userLabelInfo = {
  admin:
    "Full access for managing the dashboard, including settings, user permissions, and data monitoring",
  user: "Limited access focused on daily operations, data analysis, and customer account management, without access to sensitive configurations.",
  communication_user:
    "Minimal access, primarily for generating reports and managing communications, with limited visibility into financial data.",
};

const AddNewUser = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [userTypeOptions, setUserTypeOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserTypesLoading, setIsUserTypesLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const openTotpModal = () => {
    setShowTotpModal(true);
  };

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const addNewUser = (formik) => {
    const { values, action } = formik;
    let payload = {
      company_id: values?.company_name?.value,
      user_type: +values?.user_type?.value,
      name: values.user_name,
      email: values.email,
      mobile: values.mobile,
      set_active: true
    };

    if (values?.user_type?.name !== communicationUser) {
      payload = {
        ...payload,
        set_active: values?.is_active,
        password: values?.password,
      };
    }

    formik.setSubmitting(true);

    APIConfig.API_Client.post(
      paAPIEndpoints.USER_REGISTER.baseUrl +
        paAPIEndpoints.USER_REGISTER.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setResponseDetails({
          ...responseDetails,
          responseData: response.data,
          renderData: payload,
          isEdit: false,
        });
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        setShowResponseDetails(true);
        formik.resetForm();
        formik.setFieldValue("is_active", true);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  // Fetching Companies to show in dropdown
  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.company_id,
          label: item.common_name,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  // Fetching User Types to show in dropdown
  const getUserType = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GET_USER_TYPES.baseUrl +
        apiEndpointList.GET_USER_TYPES.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          ...item,
          value: item.id,
          label: item.display_name,
          name: item.name,
        }));

        setUserTypeOptions(options);
        setIsUserTypesLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setUserTypeOptions([]);
        setIsUserTypesLoading(false);
      });
  };

  useEffect(() => {
    getCompanyOptions();
    getUserType();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_name"
                name="company_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_name", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("company_name", true)}
                value={formik.values.company_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company"
                required={true}
                isLoading={isLoading}
                placeholder="Select company name"
                isformatOptionLabel={true}
              />

              <TextFieldSelect
                id="user_type"
                name="user_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("user_type", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("user_type", true)}
                value={formik.values.user_type}
                options={userTypeOptions}
                noOptionsMessage={() => "No Such User Type exists"}
                label="User Type"
                required={true}
                isToolTip={userLabelInfo[formik?.values?.user_type?.name]}
                isLoading={isUserTypesLoading}
                placeholder="Select user type"
                isformatOptionLabel={false}
                isClearable={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="user_name"
                name="user_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.user_name}
                touched={formik.touched.user_name}
                error={formik.errors.user_name}
                placeholder="Enter username"
                label="Name"
                required={true}
                disabled={false}
              />

              <TextFieldInput
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                touched={formik.touched.email}
                error={formik.errors.email}
                placeholder="Enter desired email"
                label="Email"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="mobile"
                name="mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
                touched={formik.touched.mobile}
                error={formik.errors.mobile}
                placeholder="Enter indian mobile number"
                label="Indian Mobile No."
                customLabelWidth="38"
                maxLength={10}
                required={true}
                disabled={false}
              />

              {formik.values.user_type?.name !== communicationUser && (
                <TextFieldInput
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  touched={formik.touched.password}
                  error={formik.errors.password}
                  placeholder="Enter password"
                  label="Password"
                  required={true}
                  disabled={false}
                />
              )}
            </div>

            {formik.values.user_type?.name !== communicationUser && (
              <div className="ui-form-inputs-section">
                <ToggleField
                  id="is_active"
                  isToolTip={false}
                  value={formik.values.is_active}
                  onChange={formik.handleChange}
                  checked={formik.values.is_active}
                  label="Status"
                  required={true}
                  disabled={false}
                />
              </div>
            )}

            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : "disabled"
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={(formik) => addNewUser(formik)}
          formik={formik}
        />
      ) : null}
      {showResponseDetails && (
        <ResponseDetails data={responseDetails} onClick={handleClick} />
      )}
    </>
  );
};

export default AddNewUser;
