import React from "react";

// styles import
import "./KYBID.scss";

function KYBID({ kybID = "", ownershipType = "" }) {

  return (
    <div className="kyb-id__block">
      <div className="kyd-id__values">
        <span>KYB ID: </span>
        {kybID || "-"}
      </div>

      <div className="kyd-id__values">
        <span>Ownership Type: </span>
        {ownershipType || "-"}
      </div>
    </div>
  );
}

export default KYBID;
