import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//ui-component-import
import FormHeading from "../../../../UI/FormHeading/FormHeading";
import FormTitle from "../../../../UI/FormTitle/FormTitle";
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import ToolTip from "../../../../UI/ToolTip/ToolTip";
import { TotpModal } from "../TotpModal/TotpModal";
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import ToggleCategory from "../../../../UI/ToggleCategory/ToggleCategory";
import EditableTextFieldInput from "../../../../UI/EditableTextFieldInput/EditableTextFieldInput";
import KYBID from "../KYBID/KYBID";

// context-imports
import OnBoardCompanyProdContext from "../../../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";

//utils-imports
import { randomUUID } from "../../../../services/randomUUID";

//API-imports
import axios from "axios";
import paAPIEndpoints from "../../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import {
  APIConfig,
  APIConfigFormData,
} from "../../../../services/apiConfiguration";
// utils-imports
import { REGEXP } from "../../../../utilities/validators/inputValidators";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

//& Initial Values
const initialValues = {
  consumer_type: "",
  pan: "",
  date_of_birth: "",
  date_of_birth_view: "",
  common_name: "",
  full_name: "",
  client_id: "",
  client_secret: "",
  email_id: "",
  company_website: "",
  mobile_number: "",
  gst_number: "",
  category: "",
  merchant_category_code: "",
  strategic_account: false,
  address_line: "",
  city: "",
  pincode: "",
  district: "",
  state: "",
  country: "India",
  is_agreement_signed: "false",
  is_hq: "false",
};

//^ date-field constant
/* The above JavaScript code is creating a new Date object representing the current date and time. It
then subtracts 16 years from the current year using the `setFullYear()` method. This code snippet is
essentially updating the date to be 16 years in the past from the current date. */
let currentDate = new Date();
currentDate.setFullYear(currentDate.getFullYear() - 16);

const {
  urlRegex,
  addressRegex,
  clientIDRegex,
  fullNameRegex,
  merchantCategoryCode,
  panRegex,
  gstRegex,
  mobileRegex,
  pincodeRegex,
  districtRegex,
  countryRegex,
  dateRegex,
} = REGEXP;

const validationSchema = Yup.object({
  consumer_type: Yup.object().shape({
    value: Yup.string().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  common_name: Yup.string()
    .required("Company Name is required")
    .matches(fullNameRegex, "Invalid characters in Company Name"),
  date_of_birth: Yup.string()
    .required("Date of Birth is required")
    .matches(
      dateRegex,
      "Date of Birth is not in correct format. Hint: DD/MM/YYYY"
    ),
  full_name: Yup.string()
    .required("Registered Name is required")
    .matches(fullNameRegex, "Invalid characters in Registered Name"),
  pan: Yup.string()
    .max(10)
    .required("PAN Number is required")
    .matches(panRegex, "Invalid PAN Number"),
  client_id: Yup.string()
    .required("Client ID is required")
    .matches(clientIDRegex, "Invalid characters in Client ID"),
  client_secret: Yup.string().required("Client Secret is required"),
  email_id: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  company_website: Yup.string().matches(
    urlRegex,
    "Invalid Company Website URL"
  ),
  mobile_number: Yup.string()
    .matches(mobileRegex, "Invalid mobile number")
    .max(10)
    .required("Mobile number is required"),
  address_line: Yup.string()
    .trim()
    .matches(addressRegex, "Invalid address line")
    .max(400, "Address line must be at most 400 characters long")
    .required("Address Line is required"),
  city: Yup.string()
    .trim()
    .max(50, "City must be at most 50 characters long")
    .required("City is required"),
  pincode: Yup.string()
    .max(6, "Pincode must be at most 6 digit long")
    .matches(pincodeRegex, "Invalid PIN code")
    .required("Pincode is required"),
  district: Yup.string()
    .trim()
    .matches(
      districtRegex,
      "District must contain only alphabets and optionally a single space"
    )
    .max(50, "District must be at most 50 characters long")
    .required("District is required"),
  state: Yup.object().shape({
    value: Yup.string().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  country: Yup.string()
    .trim()
    .matches(
      countryRegex,
      "Country must contain only alphabets and optionally a single space"
    )
    .max(50, "Country must be at most 50 characters long")
    .required("Country is required"),
  gst_number: Yup.string().matches(gstRegex, "Invalid GST Number"),
  category: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  merchant_category_code: Yup.string()
    .matches(
      merchantCategoryCode,
      "Merchant category code must be a valid integer"
    )
    .max(8, "Merchant category code  must be at most 8 characters long")
    .required("Merchant category code is required"),
  is_agreement_signed: Yup.string()
    .test(
      "is boolean",
      "Value can be either 0 or 1",
      (value) => value === "false" || value === "true"
    )
    .required(),
  is_hq: Yup.string()
    .test(
      "is boolean",
      "Value can be either 0 or 1",
      (value) => value === "false" || value === "true"
    )
    .required(),
});

const CategoryOneType = () => {
  // ^ context
  const {
    companyDetailOwnershipType,
    companySecretCreationAPI,
    setCompanyID,
    merchantDetails,
    consumerTypeDetails,
    stateDetails,
    setCompanyCommonName,
    //& KYB
    kybData,
    phoneNumber,
  } = useContext(OnBoardCompanyProdContext);

  //& KYB detail-- for single data
  const [formKybData, setFormKybData] = useState(null);

  //& KYB
  useEffect(() => {
    if (formKybData) {
      formik.setFieldValue("common_name", formKybData?.common_name || "");
      formik.setFieldValue("full_name", formKybData?.legal_name || "");
      formik.setFieldValue("pan", formKybData?.company_pan || "");
      formik.setFieldValue("company_website", formKybData?.website || "");
      formik.setFieldValue("email_id", formKybData?.primary_email_id || "");
      formik.setFieldValue(
        "mobile_number",
        formKybData?.primary_mobile_number || ""
      );
      formik.setFieldValue("gst_number", formKybData?.gstin || "");
      formik.setFieldValue("cin_llpin", formKybData?.cin_llpin || "");
      formik.setFieldValue(
        "address_line",
        formKybData?.registered_address_line1 || ""
      );
      formik.setFieldValue("city", formKybData?.registered_city || "");
      formik.setFieldValue("pincode", formKybData?.registered_pincode || "");
      formik.setFieldValue("client_secret", randomUUID());

      // ********

      // Mark fields as touched
      formik.setFieldTouched("common_name", true);
      formik.setFieldTouched("full_name", true);
      formik.setFieldTouched("pan", true);
      formik.setFieldTouched("company_website", true);
      formik.setFieldTouched("email_id", true);
      formik.setFieldTouched("mobile_number", true);
      formik.setFieldTouched("gst_number", true);
      formik.setFieldTouched("cin_llpin", true);
      formik.setFieldTouched("address_line", true);
      formik.setFieldTouched("city", true);
      formik.setFieldTouched("pincode", true);
    }
  }, [formKybData]);

  useEffect(() => {
    fetchKYBDetails(phoneNumber);
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });

  // State to hold formData
  const [formData, setFormData] = useState(null);
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const onboardCompanyDetails = (formData, formik) => {
    APIConfigFormData.API_Client.post(
      paAPIEndpoints.ONBOARDING_COMPANY_DETAIL.baseUrl +
        paAPIEndpoints.ONBOARDING_COMPANY_DETAIL.endpoint,
      formData,
      { cancelToken: source.token }
    )
      .then((response) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        let companySecretPayload = {
          company_id: response.data.data.company_id,
          client_id: formik.values.client_id,
          client_secret: formik.values.client_secret,
        };

        //& Setting Common Company ID
        setCompanyID(response.data.data.company_id);

        //& Company Secret's Creation
        companySecretCreationAPI(companySecretPayload);

        formik.resetForm();

        formData.set("is_agreement_signed", "false");
        formData.set("is_hq", "false");
        formData.set("strategic_account", false);
        formik.setFieldValue("is_agreement_signed", "false");
        formik.setFieldValue("is_hq", "false");
        formik.setFieldValue("strategic_account", false);

        const agreementFile = document.getElementById("agreementFile");
        const logoFile = document.getElementById("logoFile");
        if (agreementFile) {
          agreementFile.value = "";
        }
        if (logoFile) {
          logoFile.value = "";
        }

        formData.set("signed_agreement_doc", "");
        formData.set("brand_logo", "");
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const submitHandler = (formData, values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  // & Formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      //   const agreementFile = document.getElementById("agreementFile").files[0];
      //   const logoFile = document.getElementById("logoFile").files[0];

      //   formData.append("client_id", values.client_id);
      //   formData.append("client_secret", values.client_secret);

      const formData = new FormData();
      formData.append("consumer_type", values?.consumer_type?.value);
      formData.append(
        "ownership_type",
        formKybData?.entity_type_code
        // ownershipTypeValues[formKybData.entity_type]
      );
      // formData.append("ownership_type", companyDetailOwnershipType);

      formData.append("pan", values.pan.toUpperCase());
      formData.append("date_of_birth", values.date_of_birth);
      formData.append("is_agreement_signed", values.is_agreement_signed);

      formData.append("common_name", values.common_name);
      formData.append("full_name", values.full_name);
      formData.append("email_id", values.email_id);
      formData.append("mobile_number", values.mobile_number);
      formData.append("address_line", values.address_line);
      formData.append("city", values.city);
      formData.append("pincode", values.pincode);
      formData.append("district", values.district);
      formData.append("state", values.state.label);
      formData.append("country", values.country);
      formData.append("is_hq", values.is_hq);
      formData.append("category", values.category.label);
      formData.append("merchant_category_code", values.merchant_category_code);

      // Optional
      formData.append("company_website", values.company_website);
      formData.append("strategic_account", values.strategic_account.toString());
      formData.append("gst_number", values.gst_number.toUpperCase());
      formData.set("signed_agreement_doc", values.agreementFile);
      formData.set("brand_logo", values.logoFile);

      formik.setSubmitting(true);
      setFormData(formData); // Set formData state
      submitHandler(formData, values, action);

      //& Setting Company Common Name
      setCompanyCommonName(values?.common_name || null);
    },
  });

  // function handlers
  const handleDateSelect = (e) => {
    const date = e.target.value;
    if (!date || new Date(date) <= currentDate) {
      const [year, month, day] = date.split("-");
      const formattedDate = `${day}/${month}/${year}`;
      formik.setFieldValue("date_of_birth_view", date);
      formik.setFieldValue("date_of_birth", formattedDate);
    }
  };

  const handleFileUploadAgreement = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("agreementFile", file);
  };

  const handleFileUploadLogo = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("logoFile", file);
  };

  // Strategic account
  const [isStrategicOn, setStrategicIsOn] = useState(
    initialValues.strategic_account
  );

  const handleToggleChange = () => {
    setStrategicIsOn((prev) => {
      const newIsOn = !prev; // Calculate the new state
      formik.setFieldValue("strategic_account", newIsOn);
      return newIsOn; // Return the new state
    });
  };

  //& KYB fetchStateDetails
  const fetchKYBDetails = (phoneNumber) => {
    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_KYB_DETAILS_ONBOARDING.baseUrl +
        paAPIEndpoints.FETCH_KYB_DETAILS_ONBOARDING.endpoint,
      { phone_number: phoneNumber },
      { cancelToken: source.token }
    )
      .then((response) => {
        // Set empty full data
        setFormKybData(null);

        // Handle formKybData based on response type
        if (Array.isArray(response.data)) {
          if (response.data.length > 1) {
            // Multi-data
            setFormKybData(
              response?.data?.find((item) => item._id === kybData["_id"]) ||
                null
            );
          } else if (response.data.length === 1) {
            setFormKybData(response.data[0]); // Single data
          } else {
            setFormKybData(null);
            console.warn("No KYB data available:", response.data);
          }
        } else {
          setFormKybData(null);
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //& KYB
  useEffect(() => {
    fetchKYBDetails(phoneNumber);
  }, [phoneNumber, kybData]);

  console.log(
    "🚀 🚀 🚀 ~ file: CategoryOneType.js:470 ~ CategoryOneType ~ formik.errors:",
    formik?.errors
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          {/* form-heading */}
          <FormHeading headingText="Fill company details to continue" />
          {/* form-main */}
          <div className="ui-form-content">
            {/* COMPANY DETAILS */}
            <FormTitle titleText="Company Details" />

            {/* KYB ID */}
            <KYBID
              kybID={kybData["_id"]}
              ownershipType={formKybData?.entity_type}
            />

            {/* # 01 */}
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="consumer_type"
                name="consumer_type"
                onChange={(selectedOption) => {
                  formik.setFieldValue("consumer_type", selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("consumer_type", true)}
                value={formik.values.consumer_type}
                options={consumerTypeDetails}
                noOptionsMessage={() => "No such consumer type exists"}
                label="Consumer Type"
                required={true}
                isClearable={true}
                isLoading={consumerTypeDetails.length === 0 ? true : false}
                placeholder="Select consumer type"
              />
            </div>

            {/* # 1 */}
            <div className="ui-form-inputs-section">
              <EditableTextFieldInput
                id="common_name"
                name="common_name"
                ammendFieldName="common_name"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.common_name}
                touched={formik.touched.common_name}
                error={formik.errors.common_name}
                placeholder="Enter company name"
                label="Company Name"
                required={true}
                disabled={false}
              />

              <EditableTextFieldInput
                id="full_name"
                name="full_name"
                ammendFieldName="legal_name"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.full_name}
                touched={formik.touched.full_name}
                error={formik.errors.full_name}
                placeholder="Enter registered name"
                label="Registered Name"
                required={true}
                disabled={false}
              />
            </div>

            {/* # 1' */}
            <div className="ui-form-inputs-section">
              <EditableTextFieldInput
                id="pan"
                name="pan"
                ammendFieldName="company_pan"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pan.toUpperCase()}
                touched={formik.touched.pan}
                error={formik.errors.pan}
                placeholder="Enter PAN"
                label="PAN"
                maxLength={10}
                required={true}
                disabled={false}
              />

              <div className={`ui-form-input-section ui-form-content-input`}>
                <input
                  type="date"
                  id="date_of_birth"
                  name="date_of_birth"
                  onChange={handleDateSelect}
                  value={formik.values.date_of_birth_view}
                  max={currentDate.toISOString().split("T")[0]}
                />
                <label htmlFor="date_of_birth" className="ui-label">
                  DOB{" "}
                  <ToolTip text={""} requiredField={true} isToolTip={null} />
                </label>
              </div>
            </div>

            {/* # 2 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="client_id"
                name="client_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.client_id}
                touched={formik.touched.client_id}
                error={formik.errors.client_id}
                placeholder="Enter client ID"
                label="Client ID"
                required={true}
                disabled={false}
              />

              <TextFieldInput
                id="client_secret"
                name="client_secret"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.client_secret}
                touched={formik.touched.client_secret}
                error={formik.errors.client_secret}
                placeholder="Enter client secret"
                label="Client Secret"
                required={true}
                disabled={true}
              />
            </div>

            {/* # 3 */}
            <div className="ui-form-inputs-section">
              <EditableTextFieldInput
                id="company_website"
                name="company_website"
                ammendFieldName="website"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company_website}
                touched={formik.touched.company_website}
                error={formik.errors.company_website}
                isToolTip={
                  "Only URLs starting with http:// or https:// are accepted."
                }
                placeholder="Enter company website"
                label="Website"
                required={false}
                disabled={false}
              />

              <EditableTextFieldInput
                id="email_id"
                name="email_id"
                ammendFieldName="primary_email_id"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email_id}
                touched={formik.touched.email_id}
                error={formik.errors.email_id}
                placeholder="Enter email"
                label="Email"
                required={true}
                disabled={false}
              />
            </div>

            {/* # 4 */}
            <div className="ui-form-inputs-section">
              <EditableTextFieldInput
                id="mobile_number"
                name="mobile_number"
                ammendFieldName="primary_mobile_number"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile_number}
                touched={formik.touched.mobile_number}
                error={formik.errors.mobile_number}
                placeholder="Enter mobile number"
                label="Mobile Number"
                maxLength={10}
                required={true}
                disabled={false}
              />

              <EditableTextFieldInput
                id="gst_number"
                name="gst_number"
                ammendFieldName="gstin"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gst_number.toUpperCase()}
                touched={formik.touched.gst_number}
                error={formik.errors.gst_number}
                placeholder="Enter GST number"
                label="GST Number"
                required={false}
                disabled={false}
              />
            </div>

            {/* # 5 */}
            <div className="ui-form-inputs-section">
              {/* <TextFieldInput
              id="category"
              name="category"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
              touched={formik.touched.category}
              error={formik.errors.category}
              placeholder="Enter Category"
              label="Category"
              required={true}
              disabled={false}
            /> */}

              <TextFieldSelect
                id="category"
                name="category"
                onChange={(selectedOption) => {
                  formik.setFieldValue("category", selectedOption);
                  formik.setFieldValue(
                    "merchant_category_code",
                    selectedOption?.value || ""
                  );
                }}
                onBlur={() => formik.setFieldTouched("category", true)}
                value={formik.values.category}
                options={merchantDetails}
                noOptionsMessage={() => "No such category type exists"}
                label="Merchant Category"
                required={true}
                isClearable={true}
                isLoading={merchantDetails.length === 0 ? true : false}
                placeholder="Select merchant category"
              />

              <TextFieldInput
                id="merchant_category_code"
                name="merchant_category_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.merchant_category_code}
                touched={formik.touched.merchant_category_code}
                error={formik.errors.merchant_category_code}
                placeholder="Enter merchant category code"
                label="Merchant Category Code"
                required={true}
                disabled={true}
              />
            </div>

            {/* # 6 */}
            <div className="ui-form-inputs-section">
              <div className={`ui-form-input-section ui-form-content-input`}>
                <input
                  type="file"
                  className="signed-agreement"
                  id="agreementFile"
                  name="agreementFile"
                  // accept=".csv"
                  accept="image/png, image/jpeg, image/jpg, application/pdf"
                  onChange={handleFileUploadAgreement}
                />
                <label htmlFor="agreementFile" className="ui-label-file-field">
                  {"Signed Agreement"}
                  <ToolTip
                    text={
                      "Supported formats are JPEG, JPG, PNG and PDF upto 5 MB"
                    }
                    requiredField={false}
                    isToolTip={true}
                  >
                    <div className="ui-info-icon">
                      <img src="/images/info-icon.svg" alt="" />
                    </div>
                  </ToolTip>
                </label>
                {formik.touched.agreementFile &&
                formik.errors.agreementFile &&
                !formik.values.agreementFile ? (
                  <img
                    src="/images/invalid-input.svg"
                    className="ui-invalid-file-type"
                    alt="invalid-input"
                  />
                ) : null}
              </div>

              <div className={`ui-form-input-section ui-form-content-input`}>
                <label className="radio-yes-label">
                  <input
                    type="radio"
                    id="enable_is_agreement_signed_yes"
                    name="is_agreement_signed"
                    value="true"
                    onChange={formik.handleChange}
                    checked={formik.values.is_agreement_signed === "true"}
                  />
                  Yes{" "}
                </label>
                <label className="radio-no-label">
                  <input
                    type="radio"
                    id="disable_is_agreement_signed_yes"
                    name="is_agreement_signed"
                    value="false"
                    onChange={formik.handleChange}
                    checked={formik.values.is_agreement_signed === "false"}
                  />
                  No{" "}
                </label>
                <label htmlFor="is_agreement_signed" className="ui-label">
                  {"Agreement Signed"} <span className="required-field">*</span>
                </label>
              </div>
            </div>

            {/* # 7 */}
            <div
              className="ui-form-inputs-section"
              style={{ marginBottom: ".8rem" }}
            >
              <div className={`ui-form-input-section ui-form-content-input`}>
                <input
                  type="file"
                  id="logoFile"
                  name="logoFile"
                  // accept=".csv"
                  accept="image/jpeg, image/jpg, image/png"
                  onChange={handleFileUploadLogo}
                />
                <label htmlFor="logoFile" className="ui-label">
                  {"Brand Logo"}
                  <ToolTip
                    text={"Supported formats are JPEG, JPG and PNG upto 64 KB"}
                    requiredField={false}
                    isToolTip={true}
                  >
                    <div className="ui-info-icon">
                      <img src="/images/info-icon.svg" alt="" />
                    </div>
                  </ToolTip>
                </label>

                {formik.touched.logoFile &&
                formik.errors.logoFile &&
                !formik.values.logoFile ? (
                  <img
                    src="/images/invalid-input.svg"
                    className="ui-invalid-file-type"
                    alt="invalid-input"
                  />
                ) : null}
              </div>

              <div className={`ui-form-input-section ui-form-content-input`}>
                <ToggleCategory
                  // id="strategic_account"
                  label="Strategic Account"
                  isOn={isStrategicOn}
                  toggleSwitch={handleToggleChange}
                />
              </div>
            </div>

            {/* COMPANY ADDRESS */}
            <FormTitle titleText="Company's Registered Address" />
            {/* # 1 */}
            <div className="ui-form-inputs-section">
              <EditableTextFieldInput
                id="address_line"
                name="address_line"
                ammendFieldName="registered_address_line1"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_line}
                touched={formik.touched.address_line}
                error={formik.errors.address_line}
                placeholder="Enter address line"
                label="Address Line"
                required={true}
                disabled={false}
              />

              <EditableTextFieldInput
                id="city"
                name="city"
                ammendFieldName="registered_city"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                touched={formik.touched.city}
                error={formik.errors.city}
                placeholder="Enter city"
                label="City"
                required={true}
                disabled={false}
              />
            </div>

            {/* # 2 */}
            <div className="ui-form-inputs-section">
              <EditableTextFieldInput
                id="pincode"
                name="pincode"
                ammendFieldName="registered_pincode"
                kybID={kybData["_id"]}
                recallAPI={fetchKYBDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pincode}
                touched={formik.touched.pincode}
                error={formik.errors.pincode}
                placeholder="Enter pincode"
                label="Pincode"
                maxLength={6}
                required={true}
                disabled={false}
              />

              <TextFieldInput
                id="district"
                name="district"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.district}
                touched={formik.touched.district}
                error={formik.errors.district}
                placeholder="Enter district"
                label="District"
                required={true}
                disabled={false}
              />
            </div>

            {/* # 3 */}
            <div className="ui-form-inputs-section">
              {/* <TextFieldInput
              id="state"
              name="state"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              touched={formik.touched.state}
              error={formik.errors.state}
              placeholder="Enter State"
              label="State"
              required={true}
              disabled={false}
            /> */}

              <TextFieldSelect
                id="state"
                name="state"
                onChange={(selectedOption) => {
                  formik.setFieldValue("state", selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("state", true)}
                value={formik.values.state}
                options={stateDetails}
                noOptionsMessage={() => "No such state option exists"}
                label="State"
                required={true}
                isClearable={false}
                isLoading={stateDetails.length === 0 ? true : false}
                placeholder="Select state"
              />

              <TextFieldInput
                id="country"
                name="country"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                touched={formik.touched.country}
                error={formik.errors.country}
                placeholder="Enter country"
                label="Country"
                required={true}
                disabled={true}
              />
            </div>
          </div>

          {/* #4 */}
          <div className="ui-form-inputs-section">
            <div className={`ui-form-input-section ui-form-content-input`}>
              <label className="radio-yes-label">
                <input
                  type="radio"
                  id="enable_is_hq_yes"
                  name="is_hq"
                  value="true"
                  onChange={formik.handleChange}
                  checked={formik.values.is_hq === "true"}
                />
                Yes{" "}
              </label>
              <label className="radio-no-label">
                <input
                  type="radio"
                  id="disable_is_hq_no"
                  name="is_hq"
                  value="false"
                  onChange={formik.handleChange}
                  checked={formik.values.is_hq === "false"}
                />
                No{" "}
              </label>
              <label htmlFor="is_hq" className="ui-label">
                {"Headquarters"} <span className="required-field">*</span>
              </label>
            </div>
          </div>

          {/* form-main-ends */}

          {/* submit-button-starts */}
          <div className="ui-button-container">
            <button
              className={`${
                formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : ""
              }`}
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
              //! onClick={goToModuleProviderPA}
            >
              {formik.isSubmitting ? "Loading..." : "Submit"}
            </button>
          </div>
          {/* submit-button-ends */}
        </div>
        {/* <button onClick={toSummary}>To Next</button> */}
      </form>
      {/* TOTP modal */}
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          // submitCallback={editNewUser}
          submitCallback={(formData) => onboardCompanyDetails(formData, formik)}
          formData={formData}
          formik={formik}
        />
      ) : null}
    </>
  );
};

export default CategoryOneType;
