// Custom Hook for API Call
import { useState, useEffect, useCallback } from "react";
import { APIConfig } from "../../../services/apiConfiguration";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

const useSettlementDetails = (settlementUrn) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSettlementDetails = useCallback(async () => {
    if (!settlementUrn) return;
    setLoading(true);
    setError(null);

    try {
      const response = await APIConfig.API_Client.get(
        paAPIEndpoints.SETTLEMENT_CYCLE_DETAIL.baseUrl +
          paAPIEndpoints.SETTLEMENT_CYCLE_DETAIL.endpoint +
          `?settlement_cycle_urn=${settlementUrn}`
      );
      setData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [settlementUrn]);

  useEffect(() => {
    fetchSettlementDetails();
  }, [fetchSettlementDetails]);

  return { data, error, loading, refetch: fetchSettlementDetails };
};

export default useSettlementDetails;
