import React, { useState } from "react";
// component imports
import DataTable from "./DataTable";
import TableLoader from "../Shared/TableLoader/TableLoader";
import Error from "../Shared/Error/Error";
// api config imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
// utilities imports
import apiEndpointList from "../../config/modules/customer_management/endpoint";
import formattedDate from "../../utilities/dateTimeToDate";
import capitalizeFirstLetter from "../../utilities/capitalizeLetter";
// styles imports
import "./OnBoardingSummary.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });

    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

const OnBoardingSummary = () => {
  // function capitalizeFirstLetter(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }
  // dynamic useTable headers for data-table
  const onBoardedCompanyTableHeaders = React.useMemo(
    () => [
      {
        Header: "_",
        hideHeader: false,
        showColumnFilter: true,
        columns: [
          {
            Header: "Company Name",
            accessor: "company_common_name",
            className: "header-medium",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.company_common_name
                  ? capitalizeFirstLetter(
                      row?.row?.original?.company_common_name
                    )
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Company ID",
            accessor: "company_id",
            className: "header-small",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.company_id || "-"}
              </div>
            ),
          },
          {
            Header: "Consumer ID",
            accessor: "consumer_id",
            className: "header-small",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.consumer_id || "-"}
              </div>
            ),
          },
          {
            Header: "Consumer Name",
            accessor: "consumer_name",
            className: "header-medium",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.consumer_name
                  ? capitalizeFirstLetter(row.row.original.consumer_name)
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Consumer Type",
            accessor: "consumer_type",
            className: "header-medium",
            sort: false,
            Filter: SelectColumnFilter,
            filter: "equals",
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.consumer_type || "-"}
              </div>
            ),
          },
          {
            Header: "Consumer Email",
            accessor: "consumer_email",
            className: "header-large",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.consumer_email || "-"}
              </div>
            ),
          },
          {
            Header: "Consumer Mobile",
            accessor: "consumer_mobile",
            className: "header-medium",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.consumer_mobile || "-"}
              </div>
            ),
          },
          {
            Header: "Registration Date",
            accessor: "registration_date",
            className: "header-medium",
            sort: true,
            Cell: (row) => (
              //1: Used when date time is already coming properly formatted with spaces. This below date format shown is Date + Time but used when the date is already coming formatted in Response
              //2: Used when the date time is coming in date time timestamp form say 09092023T92042334. If you want to format a datetime response value into proper date + time (formatted with spaces properly) use formattedDate function from dateTimeToDate.js
              <div style={{ textAlign: "center" }}>
                {row.row.original.registration_date ? (
                  <>
                    {row.row.original.registration_date.split(" ")[0]}
                    <br />
                    {[
                      row.row.original.registration_date.split(" ")[1],
                      row.row.original.registration_date.split(" ")[2],
                    ].join(" ")}
                  </>
                ) : (
                  "-"
                )}
              </div>
            ),
          },
          {
            Header: "Master Consumer",
            accessor: "master_consumer",
            className: "header-large",
            Filter: SelectColumnFilter,
            filter: "equals",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.master_consumer || "-"}
              </div>
            ),
          },
          {
            Header: "Status",
            accessor: "status",
            className: "header-small",
            Filter: SelectColumnFilter,
            filter: "equals",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.status || "-"}
              </div>
            ),
          },
          {
            Header: "Merchant Category Code",
            accessor: "merchant_category_code",
            className: "header-small",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.merchant_category_code || "-"}
              </div>
            ),
          },
          {
            Header: "Merchant Category",
            accessor: "merchant_category",
            className: "header-large",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.merchant_category || "-"}
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  const [mappedValue, setMappedValue] = React.useState("");

  // Error Handling states
  const [isError, setIsError] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState({
    status: "",
    message: "",
  });

  // const getAPISummaryData = () => {
  //   APIConfig.API_Client.get(
  //     apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl + apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint
  //     // APIConfig.BASE_URL + "/v2/internal/company"
  //   )
  //     .then((response) => {
  //       let row = [];
  //       clientData = [];
  //       for (let i = 0; i < response.data.data.length; i++) {
  //         row = response.data.data[i];
  //         clientData.push(row);
  //       }
  //     })
  //     .catch((error) => {
  //       setIsError(true)
  //       setErrorResponse({
  //         status: error.response.status,
  //         message: error.message
  //       })
  //     });
  // };

  const getAPIModuleData = (searchPayload) => {
    // * Emptying the state
    // setMappedValue("");

    APIConfig.API_Client.post(
      apiEndpointList.ALL_CONSUMERS_DETAILS.baseUrl +
        apiEndpointList.ALL_CONSUMERS_DETAILS.endpoint,
      searchPayload,
      // APIConfig.BASE_URL + "/v2/internal/company_api_meter",
      { cancelToken: source.token }
    )
      .then((response) => {
        // * Table Data
        setMappedValue(response.data);
      })
      .catch((error) => {
        setIsError(true);
        setErrorResponse({
          status: error.response.status,
          message: error.message,
        });
      });
  };

  React.useEffect(() => {
    getAPIModuleData({
      // limit: 10,
      // offset: 0,
      // company_name: "",
      // company_id: "",
    });
  }, []);

  // & search company state
  const [companyName, setCompanyName] = useState("");

  const handleSearchCreditCompany = (event) => {
    setCompanyName(event.target.value);
  };

  const clearSearchHandler = () => {
    setCompanyName("");

    getAPIModuleData({
      limit: 10,
      offset: 0,
      company_name: "",
    });
  };

  const handleSearchCompany = (event) => {
    event.preventDefault();
    getAPIModuleData({
      limit: 10,
      offset: 0,
      company_name: companyName,
    });
  };

  return (
    <div className="all-company-table-container">
      {!isError ? (
        mappedValue ? (
          <DataTable
            columns={onBoardedCompanyTableHeaders}
            data={mappedValue}
            csvName="OnBoardingSummaryDetails"
          />
        ) : (
          <TableLoader />
        )
      ) : (
        <Error type={errorResponse} />
      )}
    </div>
  );
};

export default OnBoardingSummary;
