import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

//component-import
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import VPAResponseDetails from "./VPAResponseDetails/VPAResponseDetails";
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import FormHeading from "../../../UI/FormHeading/FormHeading";

// context-import
import OnBoardCompanyProdContext from "../../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";

//styles-import
import "../../../UI/TextField/TextField.scss";

// utils-import
import { REGEXP } from "../../../utilities/validators/inputValidators";
const { vpaFieldRegex } = REGEXP;

// regex-imports
const validationSchema = Yup.object({
  dynamic_vpa_name: Yup.string()
    .matches(vpaFieldRegex, "Only alphanumeric characters are allowed")
    .min(5, "Minimum length is 5 characters")
    .max(30, "Maximum length is 30 characters")
    .required("This field is required"),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const VPA = ({ setEnableVPANextToSummaryBtn }) => {
  // ^ context
  const { consumerURN, companyCommonName } = useContext(
    OnBoardCompanyProdContext
  );

  const initialValues = {
    dynamic_vpa_name: companyCommonName,
    consumer_urn: "",
    // consumer_urn: "",
  };

  /* These lines of code are initializing state variables using the `useState` hook in a React functional
component. Here's a breakdown of each state variable: */
  // const [enableDoneBtn, setEnableDoneBtn] = useState(false);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  // disable field once form is submitted
  const [disableFieldsOnFormSubmit, setDisableFieldsOnFormSubmit] =
    useState(false);

  // vpa btn validation
  const [enableVpa, setEnableVpa] = useState(true);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      let payload = {
        dynamic_vpa_name: values.dynamic_vpa_name,
        consumer_urn: consumerURN,
        // consumer_urn: "",
      };

      formik.setSubmitting(true);

      APIConfig.API_Client.post(
        paAPIEndpoints.VPA.baseUrl + paAPIEndpoints.VPA.endpoint,
        payload,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);

          // setEnableDoneBtn(true);
          // #
          setEnableVpa(false);

          // disabled fields on successful response on VPA creation
          setDisableFieldsOnFormSubmit(true);

          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg={response?.data?.message || "VPA created Successfully"}
            />,
            document.getElementById("snackbar")
          );
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
          // * snackbar for error
          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error?.response?.data?.message || "Something went wrong !"}
            />,
            document.getElementById("snackbar")
          );
          // setEnableDoneBtn(false);
          // #
          setEnableVPANextToSummaryBtn(true);
        })
        .finally(() => {
          // #
          setEnableVPANextToSummaryBtn(true);
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormHeading headingText="VPA" />
        <div className="ui-form-details">
          <div className="ui-form-content">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="consumer_urn"
                name="consumer_urn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={consumerURN}
                touched={formik.touched.consumer_urn}
                error={formik.errors.consumer_urn}
                placeholder="Enter consumer urn"
                label="Consumer URN"
                required={true}
                disabled={true}
              />

              <TextFieldInput
                id="dynamic_vpa_name"
                name="dynamic_vpa_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dynamic_vpa_name}
                touched={formik.touched.dynamic_vpa_name}
                error={formik.errors.dynamic_vpa_name}
                placeholder="Enter dynamic VPA value"
                label="Dynamic VPA Value"
                required={true}
                disabled={disableFieldsOnFormSubmit}
              />
            </div>

            {enableVpa ? (
              <div className="ui-button-container">
                <button
                  className={`formik-btn-submit ${
                    formik.isValid && !formik.isSubmitting
                      ? "active"
                      : "disabled"
                  }`}
                  type="submit"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Loading..." : "Submit"}
                </button>
              </div>
            ) : (
              <div className="admin-done-button-container">
                <button
                  className={`formik-btn-submit ${"disabled"}`}
                  type="button"
                  disabled={true}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </form>

      {showResponseDetails && (
        <VPAResponseDetails data={responseDetails} onClick={handleClick} />
      )}
    </>
  );
};

export default VPA;
