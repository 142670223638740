import React, { useContext, useRef, useState } from "react";
import ReactDom from "react-dom";

// component styles
import CloseIcon from "@material-ui/icons/Close";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";

// styles imports
import "./BulkMasterSearchSuccessModal.scss";

export const BulkMasterSearchSuccessModal = ({
  setShowModal,
  message,
  url,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [msgType, setMsgType] = useState("success");

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setMsgType("success");
      setSnackbarMessage("URL copied to clipboard!");
    } catch (err) {
      setMsgType("error");
      setSnackbarMessage("Failed to copy URL.");
    }
  };

  const renderSnackbar = () => {
    if (snackbarMessage) {
      ReactDom.render(
        <SnackbarMessage msgtype={msgType} msg={snackbarMessage} />,
        document.getElementById("snackbar")
      );
      setSnackbarMessage(null);
    }
  };

  React.useEffect(() => {
    renderSnackbar();
  }, [snackbarMessage]);

  const modalRef = useRef();
  const closeModal = (e) => {
    // Removing the logic to close the modal when clicking outside
    // if (e.target === modalRef.current) {
    //   setShowModal(false);
    // }
  };

  return ReactDom.createPortal(
    <div className="container" ref={modalRef}>
      <div className="modal">
        {/* close modal icon */}
        <button className="close-modal-btn" onClick={() => setShowModal(false)}>
          <CloseIcon />
        </button>

        {/* modal header */}
        <div className="reports-modal-heading">
          <img
            className="success-status-icon"
            src="/images/success-tick.svg"
            alt="success"
          />
          <p>Report Generated Successfully</p>
        </div>

        {/* Display the success message */}
        <div>
          <p className="reports-modal-subtitle">
            The report has been generated, you can download the file from below
            link
          </p>
          {url && (
            <p className="reports-data-unit">
              Download your report&nbsp;
              <a href={url} target="_blank" rel="noopener noreferrer">
                here
              </a>
              <img
                className="copy-img-btn"
                src="/images/copy-icon.svg"
                onClick={handleCopyUrl}
                alt="Copy URL"
              />
            </p>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
