import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
// context imports
import { modulesContext } from "../../contexts/modulesContext";
import { CallbackActionProvider } from "../../contexts/CallbackActionContext";
import { VaDetailsProvider } from "../../contexts/VaDetailsContext";
import { OnBoardCompanyPAContextProvider } from "../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";
import { DashboardUserPAContextProvider } from "../../contexts/DashboardUserPAContext/DashboardUsersContext";
import { VirtualAccountsProvider } from "../../contexts/VirtualAccountsContext/VirtualAccountsContext";
// component imports
import Header from "../../components/Header/Header";
import ProductionTabPanel from "../../components/ProductionTabPanel/ProductionTabPanel";
import SubmenuBar from "../../components/SubmenuBar/SubmenuBar";
import CallbackConfiguration from "../../components/CallbackConfiguration/CallbackConfiguration";
import OnBoardCompanyPA from "../../components/CustomerManagementPA/OnBoardCompanyPA/OnBoardCompanyPA";
import SettlementAccount from "../../components/SettlementAccount/SettlementAccount";
import ComponentWrapper from "../../UI/ComponentWrapper/ComponentWrapper";
import UserManagementPA from "../../components/UserManagementPA/UserManagementPA";
import ManageFunds from "../../components/ManageAccounts/ManageFunds/ManageFunds";
import ReTriggerCredentials from "../../components/ReTriggerCredentials/ReTriggerCredentials";
import InternalFundsTransfer from "../../components/ManageAccounts/InternalFundsTransfer/InternalFundsTransfer";
import SettlementHistory from "../../components/SettlementHistory/SettlementHistory";
import ConfigureMLA from "../../components/ConfigureMLA/ConfigureMLA";
import AllConsumers from "../../components/AllCustomers/AllConsumers";
import { IPManagementProvider } from "../../contexts/IPManagementActionContext";
import IPManagement from "../../components/IPManagement/IPManagement";
import LAMinimumBalance from "../../components/LAMinimumBalance/LAMinimumBalance";
import ParentChildMapping from "../../components/ParentChildMapping/ParentChildMapping";
import TabComponentWrapper from "../../UI/TabComponentWrapper/TabComponentWrapper";
import SettlementView from "../../components/SettlementManagement/SettlementView";
import SettlementConfig from "../../components/SettlementManagement/SettlementConfig";
import InvokeSettlement from "../../components/SettlementManagement/InvokeSettlement";
import VirtualAccounts from "../../components/VirtualAccounts/VirtualAccounts";
// styles imports
import "./ProductionDashboard.scss";
import CreditManagement from "../../components/ManageCompanies/CreditManagement/CreditManagement";
import { CreditManagementProvider } from "../../contexts/CreditManagementContext";
import SingleMasterSearch from "../../components/MasterSearch/SingleMasterSearch";
import BulkMasterSearch from "../../components/MasterSearch/BulkMasterSearch";
import Reports from "../../components/Reports/Reports";
import { OnBoardCompanyProdContextProvider } from "../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";
import OnBoardCompanyProd from "../../components/CustomerManagementProd/OnBoardCompanyProd/OnBoardCompanyProd";

const ProductionDashboard = () => {
  const location = useLocation();
  const page =
    location.pathname.split("/")[3] || location.pathname.split("/")[2];
  const [currentModuleData, setCurrentModuleData] = useState({
    module: "manage-companies",
    display_name: "Manage Companies",
    path: "/dashboard/manage-companies/onboard-company",
    submenus: [
      {
        module: "onboard-company",
        display_name: "Onboard Company",
        path: "/dashboard/manage-companies/onboard-company",
        submenus: [],
        icon_path: "/images/onboard-company-icon.svg",
      },
      {
        module: "all-consumers",
        display_name: "All Consumers",
        path: "/dashboard/manage-companies/all-consumers",
        submenus: [],
        icon_path: "/images/all-consumers-icon.svg",
      },
      {
        module: "callbacks",
        display_name: "Callbacks",
        path: "/dashboard/manage-companies/callbacks",
        submenus: [],
        icon_path: "/images/callback-configuration-icon.svg",
      },
      {
        module: "manage-credits",
        display_name: "Manage Credits",
        path: "/dashboard/manage-companies/manage-credits",
        submenus: [],
        icon_path: "/images/credits-management-icon.svg",
      },
      {
        module: "dashboard-users",
        display_name: "Dashboard Users",
        path: "/dashboard/manage-companies/dashboard-users",
        submenus: [],
        icon_path: "/images/user-management-icon.svg",
      },
      {
        module: "settlement-account",
        display_name: "Settlement Account",
        path: "/dashboard/manage-companies/settlement-account",
        submenus: [],
        icon_path: "/images/settlement-active.svg",
      },
      {
        module: "send-credentials",
        display_name: "Send Credentials",
        path: "/dashboard/manage-companies/send-credentials",
        submenus: [],
        icon_path: "/images/send-credentials.svg",
      },
      {
        module: "master-account-setup",
        display_name: "Master Account Setup",
        path: "/dashboard/manage-companies/master-account-setup",
        submenus: [],
        icon_path: "/images/master-account-setup.svg",
      },
      {
        module: "manage-ips",
        display_name: "Manage IPs",
        path: "/dashboard/manage-companies/manage-ips",
        submenus: [],
        icon_path: "/images/ipwhitelist-active.svg",
      },
      {
        module: "settlement-history",
        display_name: "Settlement History",
        path: "/dashboard/manage-companies/settlement-history",
        submenus: [],
        icon_path: "/images/settlement-history.svg",
      },
      {
        module: "minimum-balance",
        display_name: "LA Minimum Balance",
        path: "/dashboard/manage-companies/minimum-balance",
        submenus: [],
        icon_path: "/images/minimum_balance.svg",
      },
      {
        module: "parent-child-mapping",
        display_name: "Parent Child Mapping",
        path: "/dashboard/manage-companies/parent-child-mapping",
        submenus: [],
        icon_path: "/images/parent-child.svg",
      },
    ],
  });

  // for showing LogoutModal
  // const [showModal, setShowModal] = useState(false);

  // let logoutTimer;

  // function startAutoLogout() {
  //   // Start timer only after user is logged in.
  //   if (isLoggedIn()) {
  //     logoutTimer = setTimeout(logout, 60 * 60 * 1000); // 60 minutes in milliseconds"
  //   }
  // }
  // function logout() {
  //   setShowModal(true);
  // }
  // call auto-logout function only when loggedIn changes.
  // useEffect(() => {
  //   startAutoLogout();
  // }, [isLoggedIn()]);

  return (
    <React.Fragment>
      {/* Logout Modal */}
      {/* {showModal && <LogoutModal />} */}

      <div className="dashboard">
        <modulesContext.Provider
          value={{ currentModuleData, setCurrentModuleData }}
        >
          <Header />
          <ProductionTabPanel />
          <Route path="/dashboard">
            <Redirect to="/dashboard/manage-companies/onboard-company" />
            {page === "onboard-company" ||
            page === "all-consumers" ||
            page === "callbacks" ||
            page === "manage-credits" ||
            page === "dashboard-users" ||
            page === "settlement-account" ||
            page === "send-credentials" ||
            page === "master-account-setup" ||
            page === "virtual-accounts" ||
            page === "manage-funds" ||
            page === "manage-ips" ||
            page === "settlement-history" ||
            page === "internal-fund-transfer" ||
            page === "settlement-cycle" ||
            page === "search" ||
            page === "reports" ||
            page === "minimum-balance" ||
            page === "parent-child-mapping" ||
            page === "invoke-settlement" ? (
              <Redirect to={location.pathname} />
            ) : (
              <Redirect to="/dashboard/manage-companies/onboard-company" />
            )}
          </Route>
          <React.Fragment>
            <div className="submenu-and-content">
              <SubmenuBar />
              <div className="content">
                <Route path="/dashboard/manage-companies/onboard-company">
                  {/* staging */}
                  {/* <OnBoardCompanyPAContextProvider>
                    <OnBoardCompanyPA />
                  </OnBoardCompanyPAContextProvider> */}

                  {/* qa and prod */}
                  <OnBoardCompanyProdContextProvider>
                    <OnBoardCompanyProd />
                  </OnBoardCompanyProdContextProvider>
                </Route>
                <Route path="/dashboard/manage-companies/all-consumers">
                  <VaDetailsProvider>
                    <AllConsumers />
                  </VaDetailsProvider>
                </Route>
                <Route path="/dashboard/manage-companies/callbacks">
                  <CallbackActionProvider>
                    <CallbackConfiguration />
                  </CallbackActionProvider>
                </Route>
                <Route path="/dashboard/manage-companies/manage-credits">
                  <CreditManagementProvider>
                    <ComponentWrapper
                      heading="Manage Credits"
                      component={<CreditManagement />}
                    />
                  </CreditManagementProvider>
                </Route>
                <Route path="/dashboard/manage-companies/dashboard-users">
                  <DashboardUserPAContextProvider>
                    <UserManagementPA />
                  </DashboardUserPAContextProvider>
                </Route>
                <Route path="/dashboard/manage-companies/manage-ips">
                  <IPManagementProvider>
                    <IPManagement />
                  </IPManagementProvider>
                </Route>
                <Route path="/dashboard/manage-companies/settlement-account">
                  <ComponentWrapper
                    heading="Settlement Account"
                    component={<SettlementAccount />}
                  />
                </Route>
                <Route path="/dashboard/manage-companies/settlement-history">
                  <ComponentWrapper
                    heading="Settlement History"
                    component={<SettlementHistory />}
                    isTable={true}
                  />
                </Route>
                <Route path="/dashboard/manage-companies/minimum-balance">
                  <ComponentWrapper
                    heading="LA Minimum Balance"
                    component={<LAMinimumBalance />}
                  />
                </Route>
                <Route path="/dashboard/manage-companies/parent-child-mapping">
                  <ComponentWrapper
                    heading="Parent Child Mapping"
                    component={<ParentChildMapping />}
                  />
                </Route>
                <Route path="/dashboard/manage-companies/send-credentials">
                  <ComponentWrapper
                    heading="Send Credentials"
                    component={<ReTriggerCredentials />}
                  />
                </Route>
                <Route path="/dashboard/manage-companies/master-account-setup">
                  <ComponentWrapper
                    // Master Account Setup
                    heading="Master Account Setup"
                    component={<ConfigureMLA />}
                  />
                </Route>
                {/* VIRTUAL ACCOUNTS */}
                <Route path="/dashboard/manage-accounts/virtual-accounts">
                  <VirtualAccountsProvider>
                    <VirtualAccounts />
                  </VirtualAccountsProvider>
                </Route>

                {/* Manage Accounts */}
                <Route path="/dashboard/manage-accounts/manage-funds">
                  <ComponentWrapper
                    heading="Unload Funds"
                    component={<ManageFunds />}
                  />
                </Route>
                <Route path="/dashboard/manage-accounts/internal-fund-transfer">
                  <ComponentWrapper
                    heading="Internal Fund Transfer"
                    component={<InternalFundsTransfer />}
                  />
                </Route>
                <Route path="/dashboard/manage-settlement/settlement-cycle">
                  <TabComponentWrapper
                    heading="Settlement Cycle"
                    tabs={[
                      {
                        label: "Settlement View",
                        value: "settlement-view",
                        component: <SettlementView />,
                      },
                      {
                        label: "Settlement Config",
                        value: "settlement-config",
                        component: <SettlementConfig />,
                      },
                    ]}
                  />
                </Route>
                <Route path="/dashboard/manage-settlement/invoke-settlement">
                  <ComponentWrapper
                    heading="Invoke Settlement"
                    component={<InvokeSettlement />}
                  />
                </Route>
                <Route path="/dashboard/master-search/search">
                  <TabComponentWrapper
                    heading="Master Search"
                    tabs={[
                      {
                        label: "SINGLE",
                        value: "single-master-search",
                        component: <SingleMasterSearch />,
                      },
                      {
                        label: "BULK",
                        value: "bulk-master-search",
                        component: <BulkMasterSearch />,
                      },
                    ]}
                  />
                </Route>
                <Route path="/dashboard/reports">
                  <ComponentWrapper heading="Reports" component={<Reports />} />
                </Route>
              </div>
            </div>
          </React.Fragment>
        </modulesContext.Provider>
      </div>
    </React.Fragment>
  );
};

export default ProductionDashboard;
