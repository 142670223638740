import ReactDOM from "react-dom";
// Components
import { Box, Chip } from "@mui/material";
import SnackbarMessage from "../../components/SnackbarMessage/SnackbarMessage";

// formatting data to allow pasting in separate cells.
function formatDataForExcel(data) {
  const rows = [];

  // Add data rows
  data.forEach((item) => {
    const row = [];
    for (const [key, value] of Object.entries(item)) {
      row.push(`${key}\t${value}`);
    }
    rows.push(row.join("\n"));
  });

  // Join rows into a single string
  const excelData = rows.join("\n\n");

  return excelData;
}

const CopyResponseListObjects = ({ label, color, variant, data, iconSrc }) => {
  const handleCopy = async () => {
    try {
      const copyableText = formatDataForExcel(data);
      await navigator.clipboard.writeText(copyableText);
      ReactDOM.render(
        <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
        document.getElementById("snackbar")
      );
    } catch (err) {
      ReactDOM.render(
        <SnackbarMessage msgtype="Error" msg={err ?? "Failed to copy"} />,
        document.getElementById("snackbar")
      );
    }
  };

  // inline styles
  const styles = {
    marginLeft: "8px",
    width: "16px",
    height: "16px",
  };

  return (
    <Chip
      label={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <span>{label}</span>
          <img src={iconSrc} alt="Copy" style={styles} />
        </Box>
      }
      color={color}
      variant={variant}
      onClick={handleCopy}
      sx={{ cursor: "pointer" }}
    />
  );
};

export default CopyResponseListObjects;
