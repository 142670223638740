export function convertCasesToCapitalize(obj) {
  // Utility function to format keys
  const formatKey = (key) => {
    return key
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  // Create a new object to hold the formatted keys and original values
  const newObj = {};

  // Iterate over the original object's keys
  for (const [key, value] of Object.entries(obj)) {
    newObj[formatKey(key)] = value; // Assign the formatted key and original value to the new object
  }

  return newObj; // Return the new object
}
