import React from "react";
import ReactDOM from "react-dom";

import useSettlementDetails from "./apiHooks/settlementAPIHook";
import ConsumerListDialog from "./ConsumerListDialog";

const SettlementViewDetailBlock = ({ settlementUrn }) => {
  // custom hook
  const { data, error, loading } = useSettlementDetails(settlementUrn);

  // modal handler
  const handleIconClick = (data) => {
    ReactDOM.render(
      <ConsumerListDialog listData={data} />,
      document.getElementById("add-customer-components")
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message || "Failed to Fetch"}</p>;

  const renderCompanyConsumers = () => {
    if (!data?.data || !data?.data?.comapny_consumer_list) return "-";

    // Display only the first company's name
    const firstCompany = data?.data?.comapny_consumer_list[0];
    if (!firstCompany) return "-"; // Safeguard in case the list is empty

    return (
      <div className="consumers-urn__value">
        <strong className="consumer-value__label">Company Name:</strong>{" "}
        <span className="consumer-value">{firstCompany?.company_name}</span>
        <br />
        <strong className="consumer-value__label">Consumers:</strong>{" "}
        <span className="consumer-value">{firstCompany.consumers[0]}</span>
      </div>
    );
  };

  return (
    <li className="response-list-item">
      <span className="field">Consumer with Company: </span>
      <span style={{ marginLeft: "auto" }} className="settlement-urn__details">
        {renderCompanyConsumers()}
        <button
          className="view-all__action"
          onClick={() => handleIconClick(data?.data?.comapny_consumer_list)}
        >
          View All
        </button>
      </span>
    </li>
  );
};

export default SettlementViewDetailBlock;
