import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";

//API imports
import axios from "axios";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { APIConfig } from "../../../services/apiConfiguration";

//component-import
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import FormTitle from "../../../UI/FormTitle/FormTitle";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import ToggleField from "../../../UI/ToggleField/ToggleField";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

// context imports
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";
import OnBoardCompanyProdContext from "../../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";

// styles import
import "./PaymentChannelProd.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const PaymentChannelProd = () => {
  // ^ context
  const { companyID, consumerURN, toSummary } = useContext(
    OnBoardCompanyProdContext
  );
  // & provider Options state
  const [providerOptions, setProviderOptions] = useState([]); // Add this state

  useEffect(() => {
    const fetchProviders = () => {
      APIConfig.API_Client.post(
        paAPIEndpoints.FETCH_PROVIDERS_LIST.baseUrl +
          paAPIEndpoints.FETCH_PROVIDERS_LIST.endpoint,
        { cancelToken: source.token }
      )
        .then((response) => {
          const options = response.data.map((provider) => ({
            label: provider.display_name,
            value: provider.four_character_bank_code,
          }));
          console.log("options", options);
          setProviderOptions(options);
          // Yes Bank as default
          formik.setFieldValue("provider_code", "yesb");
          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg="Providers fetched successfully"
            />,
            document.getElementById("snackbar")
          );
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.error("Error fetching providers:", error);
            ReactDOM.render(
              <SnackbarMessage
                msgtype="Error"
                msg={error?.response?.data?.message || "Something went wrong!"}
              />,
              document.getElementById("snackbar")
            );
          }
        });
    };

    fetchProviders();

    return () => {
      source.cancel("Component unmounted");
    };
  }, []);

  const handleSubmit = () => {
    const providerData = Object.entries({
      provider_code: formik.values.provider_code,
      is_upi: formik.values.is_upi,
      is_upi_autopay: formik.values.is_upi_autopay,
      is_enach: formik.values.is_enach,
      is_ecollect: formik.values.is_ecollect,
      is_block_push_transaction: formik.values.is_block_push_transaction,
      ecollect_code: formik.values.ecollect_code,
      enach_utility_code: formik.values.enach_utility_code,
    }).reduce((acc, [key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const payload = {
      company_id: formik.values.company_id,
      consumer_urn: formik.values.consumer_urn,
      providers: [providerData],
    };

    APIConfig.API_Client.put(
      paAPIEndpoints.PAYMENT_CHANNEL_SETUP.baseUrl +
        paAPIEndpoints.PAYMENT_CHANNEL_SETUP.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="Success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        toSummary();
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
      });
  };
  const formik = useFormik({
    initialValues: {
      company_id: companyID || "",
      consumer_urn: consumerURN || "",
      provider_code: "",
      is_upi: false,
      is_upi_autopay: false,
      is_enach: false,
      is_ecollect: false,
      is_block_push_transaction: false,
      ecollect_code: "",
      enach_utility_code: "",
    },
    validationSchema: Yup.object().shape({
      company_id: Yup.string().required("Company ID is required"),
      consumer_urn: Yup.string().required("Consumer URN is required"),
      provider_code: Yup.string().required("Provider is required"),
      ecollect_code: Yup.string().when("is_ecollect", {
        is: true,
        then: () =>
          Yup.string().required(
            "eCollect Code is required when eCollect is enabled"
          ),
        otherwise: () => Yup.string(),
      }),
      enach_utility_code: Yup.string().when("is_enach", {
        is: true,
        then: () =>
          Yup.string().required(
            "eNACH Utility Code is required when eNACH is enabled"
          ),
        otherwise: () => Yup.string(),
      }),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  // Function to check if any toggle is enabled
  const isAnyToggleEnabled = () => {
    return (
      formik.values.is_upi ||
      formik.values.is_upi_autopay ||
      formik.values.is_enach ||
      formik.values.is_ecollect ||
      formik.values.is_block_push_transaction
    );
  };

  // Function to check if required fields are filled
  const areRequiredFieldsFilled = () => {
    const hasRequiredFields =
      formik.values.company_id &&
      formik.values.consumer_urn &&
      formik.values.provider_code;
    // If eCollect is enabled and its code is filled
    if (formik.values.is_ecollect && !formik.values.ecollect_code) {
      return false;
    }

    // If eNACH is enabled and its utility code is filled
    if (formik.values.is_enach && !formik.values.enach_utility_code) {
      return false;
    }
    return hasRequiredFields;
  };

  const isSubmitEnabled = () => {
    return (
      isAnyToggleEnabled() && areRequiredFieldsFilled() && !formik.isSubmitting
    );
  };

  //* Prefetch e-collect code
  const fetchECollectCode = (bankCode) => {
    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_ECOLLECT_CODE.baseUrl +
        paAPIEndpoints.FETCH_ECOLLECT_CODE.endpoint,
      {
        provider_code: bankCode,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        formik.setFieldValue(
          "ecollect_code",
          response?.data[0]?.e_collect_code
        );
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    if (formik?.values?.provider_code) {
      fetchECollectCode(formik.values.provider_code);
    }
  }, [formik.values.provider_code]);

  return (
    <FormikProvider value={formik}>
      <form className="payment-channel-form">
        <div className="ui-form-details">
          <div className="ui-form-content">
            <FormTitle titleText="Payment Channel Mapping" />
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="company_id"
                name="company_id"
                value={formik.values.company_id}
                placeholder={companyID}
                label="Company ID"
                required={true}
                disabled={true}
              />
              <TextFieldInput
                id="consumer_urn"
                name="consumer_urn"
                value={formik.values.consumer_urn}
                placeholder={consumerURN}
                label="Consumer URN"
                required={true}
                disabled={true}
              />
            </div>
            <hr />
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="provider_code"
                name="provider_code"
                options={providerOptions} // Using dynamic options here
                placeholder="Select Provider"
                label="Provider"
                value={
                  providerOptions.find(
                    (option) => option.value === formik.values.provider_code
                  ) || null
                }
                onChange={(option) => {
                  formik.setFieldValue(
                    "provider_code",
                    option ? option.value : ""
                  );
                }}
                required
              />
            </div>

            <div className="ui-form-inputs-section">
              <div className="ui-toggle-group">
                <ToggleField
                  id="is_upi"
                  label="UPI"
                  checked={formik.values.is_upi}
                  onChange={(e) =>
                    formik.setFieldValue("is_upi", e.target.checked)
                  }
                />
                <ToggleField
                  id="is_upi_autopay"
                  label="UPI Autopay"
                  checked={formik.values.is_upi_autopay}
                  onChange={(e) =>
                    formik.setFieldValue("is_upi_autopay", e.target.checked)
                  }
                />
                <ToggleField
                  id="is_enach"
                  label="eNACH"
                  checked={formik.values.is_enach}
                  onChange={(e) =>
                    formik.setFieldValue("is_enach", e.target.checked)
                  }
                />
                <ToggleField
                  id="is_ecollect"
                  label="eCollect"
                  checked={formik.values.is_ecollect}
                  onChange={(e) =>
                    formik.setFieldValue("is_ecollect", e.target.checked)
                  }
                />
                <ToggleField
                  id="is_block_push_transaction"
                  label="Block Push Transaction"
                  checked={formik.values.is_block_push_transaction}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "is_block_push_transaction",
                      e.target.checked
                    )
                  }
                />
              </div>
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="ecollect_code"
                name="ecollect_code"
                value={formik.values.ecollect_code}
                onChange={formik.handleChange}
                label="eCollect Code"
                required={formik.values.is_ecollect}
                onBlur={formik.handleBlur}
                touched={formik.touched.ecollect_code}
                error={formik.errors.ecollect_code}
              />
              <TextFieldInput
                id="enach_utility_code"
                name="enach_utility_code"
                value={formik.values.enach_utility_code}
                onChange={formik.handleChange}
                label="eNACH Utility Code"
                required={formik.values.is_enach}
                onBlur={formik.handleBlur}
                touched={formik.touched.enach_utility_code}
                error={formik.errors.enach_utility_code}
              />
            </div>
          </div>
          <div className="ui-channel-button-container">
            <button
              className={isSubmitEnabled() ? "active" : "disabled"}
              type="button"
              onClick={handleSubmit}
              disabled={!isSubmitEnabled()}
            >
              {formik.isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default PaymentChannelProd;
