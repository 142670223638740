import React from "react";

// styles import
import "./MessageBlock.scss";

const MessageBlock = ({ message, styleClass = "form-message-block" }) => {
  return <div className={styleClass}>{message}</div>;
};

export default MessageBlock;
