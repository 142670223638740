import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";

//component-import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import MasterSearchResponseDetails from "./MasterSearchResponseDetails";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import { TotpModal } from "./TotpModal/TotpModal";

//utility-import
import { scrollIntoView } from "../../utilities/scrollIntoView";
import { randomUUID } from "../../services/randomUUID";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SingleMasterSearch = () => {
  const [responseDetails, setResponseDetails] = useState([]);
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const responseRef = useRef(null);

  const singleSearchSubmit = () => {
    setShowResponseDetails(false);
    setResponseDetails(null);
    setErrorResponse(null);
    const payload = {
      [formik.values.searchParameter.value]: formik.values.parameterValue,
    };

    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.SINGLE_MASTER_SEARCH.baseUrl +
        paAPIEndpoints.SINGLE_MASTER_SEARCH.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.data.length > 0) {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
        } else {
          ReactDOM.render(
            <SnackbarMessage msgtype="Error" msg={"No Records Found!"} />,
            document.getElementById("snackbar")
          );
        }
        formik.resetForm();
      })
      .catch((error) => {
        setResponseDetails(null);
        setShowResponseDetails(false);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
        setIsLoading(false);
        if (responseRef.current) {
          scrollIntoView(responseRef.current, { behavior: "smooth" });
        }
      });
  };

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      searchParameter: null,
      parameterValue: "",
    },
    validationSchema: Yup.object({
      searchParameter: Yup.object().required("Search parameter is required"),
      parameterValue: Yup.string()
        .min(3, "Minimum 3 characters required")
        .max(100, "Maximum 100 characters allowed")
        .required("Parameter value is required"),
    }),
    onSubmit: async (values, actions) => {
      setIsLoading(true);
      submitHandler(values, actions);
    },
  });

  const handleCloseDetails = (index) => {
    setResponseDetails((prevDetails) =>
      prevDetails.filter((_, i) => i !== index)
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">Search Parameters</h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="searchParameter"
                name="searchParameter"
                onChange={(selectedOption) => {
                  formik.setFieldValue("searchParameter", selectedOption);
                  formik.setFieldValue("parameterValue", "");
                }}
                onBlur={() => formik.setFieldTouched("searchParameter", true)}
                value={formik.values.searchParameter}
                options={[
                  {
                    value: "bank_reference_number",
                    label: "Bank reference number",
                  },
                  {
                    value: "decentro_company_urn",
                    label: "Decentro company URN",
                  },
                  { value: "company_urn", label: "Company URN" },
                ]}
                required={true}
                label="Search Parameter"
              />
              {formik.touched.searchParameter &&
                formik.errors.searchParameter && (
                  <div className="error">
                    {formik.errors.searchParameter.label}
                  </div>
                )}

              {formik.values.searchParameter && (
                <TextFieldInput
                  id="parameterValue"
                  name="parameterValue"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.parameterValue}
                  placeholder="Parameter Value (required)"
                  required
                  touched={formik.touched.parameterValue}
                  error={formik.errors.parameterValue}
                  label="Parameter Value"
                />
              )}
            </div>
            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : "disabled"
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
        {showResponseDetails && (
          <div ref={responseRef}>
            {responseDetails.map((item, index) => {
              return (
                <MasterSearchResponseDetails
                  key={index}
                  data={item}
                  onClick={() => handleCloseDetails(index)}
                />
              );
            })}
          </div>
        )}
        {errorResponse && (
          <div className="error-response">
            <h3>Error:</h3>
            <pre>{JSON.stringify(errorResponse, null, 2)}</pre>
          </div>
        )}
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={singleSearchSubmit}
          formik={formik}
        />
      ) : null}
    </>
  );
};

export default SingleMasterSearch;
